import React, { useEffect, useContext } from 'react'
import { TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { Page, PageProps, GapWrapper } from '../components/Page'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { FormValues } from '../types'
import { STATE_UNEMPLOYMENT_INSURANCE_TYPE } from '../lib/constants'

export const validationSchema = yup.object().shape({
  contact_name: yup
    .string()
    .required('Please provide a name')
    .typeError('Please provide a name')
    .nullable(),
  contact_email: yup
    .string()
    .email('Please provide a valid email')
    .required('Please provide an email')
    .typeError('Please provide a valid email')
    .nullable(),
  contact_phone_number: yup
    .string()
    .required('Please provide a phone number')
    .typeError('Please provide a valid phone number')
    .nullable(),
  contact_title: yup
    .string()
    .required('Please provide a job title')
    .typeError('Please provide a job title')
    .nullable()
})

const ApplicantDetails: Page = ({
  validateFormAndNavigate,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  pageNumber,
  error,
  progress,
  showPoweredByMiddesk,
  managedRegistrationTypesWithFQ
}: PageProps) => {
  const { values, setFieldTouched } = useFormikContext<FormValues>()
  const { state } = useContext(ApplicationContext)

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)
  }, [])

  const registrationForCaEdd =
    state === 'CA' &&
    managedRegistrationTypesWithFQ.includes(STATE_UNEMPLOYMENT_INSURANCE_TYPE)

  const contactPhoneSublabel = registrationForCaEdd
    ? 'The California EDD will call this number within a few days of your order to complete your registration'
    : ''

  return (
    <>
      <GapWrapper>
        <TextField
          placeholder='Enter name'
          name='contact_name'
          label='Name'
          showErrorMessage
          showErrorWithoutBlur
        />
        <TextField
          name='contact_email'
          label='Email'
          placeholder='Enter email'
          showErrorMessage
          showErrorWithoutBlur
        />
        <TextField
          name='contact_phone_number'
          label='Phone number'
          sublabel={contactPhoneSublabel}
          placeholder='Enter phone number'
          type='phone'
          showErrorMessage
          showErrorWithoutBlur
        />
        <TextField
          name='contact_title'
          label='Job title'
          placeholder='Enter job title'
          showErrorMessage
          showErrorWithoutBlur
        />
      </GapWrapper>
      <NewRegistrationIntakeFooter
        {...{
          values,
          isSubmitting,
          error,
          isDisabled: false,
          onClick: () =>
            validateFormAndNavigate({
              values,
              schema: validationSchema,
              submit: false,
              setFieldTouched
            }),
          progress,
          title: 'Contact information',
          showPoweredByMiddesk,
          onCancel: pageNumber > 0 ? onCancel : undefined
        }}
      />
    </>
  )
}

ApplicantDetails.validationSchema = validationSchema

ApplicantDetails.pageName = 'ApplicantDetails'
ApplicantDetails.title = () => 'Contact information'
ApplicantDetails.description = () =>
  'We will reach out to this contact if we require any additional follow-up.'

export default ApplicantDetails
