import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Icons, MetaTag } from '@middesk/components'
import { COLORS } from './System/Colors'
import { TaxAgencyRegistration } from '../types'
import {
  BLOCKED_BY_GOVERNMENT,
  COMPLETED,
  DUPLICATE,
  GOVERNMENT_PROCESSING,
  INFORMATION_REQUESTED,
  MIDDESK_PROCESSING,
  NOT_INCLUDED,
  PAYROLL_DATE,
  HIRE_DATE,
  SECRETARY_OF_STATE,
  SECRETARY_OF_STATE_TYPE,
  UNMANAGED
} from '../lib/constants'
import { getTaxAgencyRegistrationStatusLabel } from '../lib/helpers'
import capitalize from 'lodash/capitalize'
import moment from 'moment'

const { ChevronRight } = Icons

const ActionRequiredStatus = styled.div`
  display: inline-block;
  position: relative;
  bottom: 1px;
`

const ArrowContainer = styled.div`
  padding-left: 7px;
  display: inline-block;
  position: relative;
  top: 1px;
`

const StyledChevronRight = styled(ChevronRight)`
  stroke: ${COLORS.magenta};
  height: 75%;
  width: 75%;
`

export const getToolTip = ({
  status,
  agency,
  contact_email,
  blocked_by,
  blocked_until
}: TaxAgencyRegistration) => {
  switch (status) {
    case BLOCKED_BY_GOVERNMENT:
      switch (blocked_by) {
        case SECRETARY_OF_STATE_TYPE:
          return `The ${agency} requires that the Secretary of State registration is complete before registering`
        case PAYROLL_DATE:
          return `The registration is blocked until the employee's first payroll date of ${moment(
            blocked_until
          ).format('MMMM Do YYYY')} has passed`
        case HIRE_DATE:
          return `The registration is blocked until the employee's hire date of ${moment(
            blocked_until
          ).format('MMMM Do YYYY')} has passed`
        default:
          return `The ${agency} will not accept this registration ${
            blocked_until
              ? `until ${moment(blocked_until).format('MMMM Do YYYY')}`
              : 'at present'
          } due to ${capitalize(blocked_by?.replaceAll('_', ' '))}`
      }
    case MIDDESK_PROCESSING:
      return 'Middesk is currently processing your registration and will contact you if additional information is needed'
    case GOVERNMENT_PROCESSING:
      return `The ${agency} is currently processing your registration. Middesk will collect the account details when they are available`
    case COMPLETED:
      if (agency === SECRETARY_OF_STATE) {
        return 'We have registered you with the Secretary of State and will serve as your Registered Agent'
      } else {
        return `We manage your state compliance by receiving your mail from the ${agency}<br/>
        and notifying you when new mail is available`
      }
    case NOT_INCLUDED:
      if (agency === SECRETARY_OF_STATE) {
        return 'This state does not require you to be registered with the Secretary of State'
      } else {
        return `The ${agency} was not registered by Middesk`
      }
    case UNMANAGED:
      return 'Your account is not managed by Middesk.'
    case DUPLICATE:
      return `We found that you have existing registrations with the ${agency}.<br/>
      Please check ${contact_email} for an email from our team.<br/>
      If you didn't receive an email, contact us at agent@middesk.com`
    default:
      return ''
  }
}

export const getStatusTagColor = (status: string) => {
  switch (status) {
    case INFORMATION_REQUESTED:
      return 'warning'
    case COMPLETED:
      return 'primary'
    case BLOCKED_BY_GOVERNMENT:
      return 'inactive'
    case MIDDESK_PROCESSING:
      return 'secondary'
    case GOVERNMENT_PROCESSING:
      return 'yellow'
    case NOT_INCLUDED:
    case DUPLICATE:
    case UNMANAGED:
      return 'unknown'
    default:
      return 'info'
  }
}

const StatusTag = ({
  taxAgencyRegistration,
  hideTooltip
}: {
  taxAgencyRegistration: TaxAgencyRegistration
  hideTooltip?: boolean
}) => {
  const {
    status,
    state,
    incomplete_exceptions,
    company_id
  } = taxAgencyRegistration
  const { push } = useHistory()
  const allowActionNavigation =
    INFORMATION_REQUESTED === status && incomplete_exceptions[0]

  const handleClick = (e: any) => {
    if (!allowActionNavigation) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    if (incomplete_exceptions.length === 1) {
      window.location.href = incomplete_exceptions[0].url
    } else {
      const stateFilter = state ? `?state=${state}` : ''
      push(`/companies/${company_id}/actions${stateFilter}`)
    }
  }

  const message = getToolTip(taxAgencyRegistration)
  const label = getTaxAgencyRegistrationStatusLabel(status)

  if (hideTooltip && !allowActionNavigation) {
    return <MetaTag type={getStatusTagColor(status)}>{label}</MetaTag>
  }

  return (
    <MetaTag type={getStatusTagColor(status)} onClick={handleClick}>
      <div
        style={{
          cursor: message || allowActionNavigation ? 'pointer' : 'default'
        }}
        data-multiline={true}
        data-tip={message}
      >
        {allowActionNavigation ? (
          <>
            <ActionRequiredStatus>{label}</ActionRequiredStatus>
            <ArrowContainer>
              <StyledChevronRight />
            </ArrowContainer>
          </>
        ) : (
          <>{label}</>
        )}
      </div>
    </MetaTag>
  )
}

export default StatusTag
