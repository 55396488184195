import React, { useContext } from 'react'

import { useParams, useLocation, useHistory } from 'react-router'
import qs from 'qs'

import GuestAccessDenied from './GuestAccessDenied'
import { AuthContext } from '../contexts/AuthProvider'
import GuestDashWrapper from '../pages/GuestDashboard'

import API from '../lib/api'
import { APIGuestToken } from '../lib/api/guestToken'

const GuestDashboard = () => {
  const { companyId } = useParams<{ companyId: string }>()
  const { fetching, user, setFetchingUser } = useContext(AuthContext)
  const { push } = useHistory()
  const { search } = useLocation()
  const { enhanced_guest_token, guest_token } = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  const showInvalid = () => {
    const sandbox = API.sandboxMode() ? '&sandbox=true' : ''

    push(`/guest_dashboard/${companyId}?guest_token=invalid${sandbox}`)
  }

  if (enhanced_guest_token) {
    APIGuestToken.get_guest_token(companyId, enhanced_guest_token as string)
      .then(json => {
        if (json.guest_token) {
          const sandbox = API.sandboxMode() ? '?sandbox=true' : ''

          API.guestToken(json.guest_token) && setFetchingUser(true)

          push(`/guest_dashboard/${companyId}${sandbox}`)
        } else {
          showInvalid()
        }
      })
      .catch(() => {
        showInvalid()
      })
  }

  if (fetching || enhanced_guest_token) {
    return <></>
  }

  return (
    <>
      {(!fetching && !user) || guest_token ? (
        <GuestAccessDenied />
      ) : (
        <GuestDashWrapper companyId={companyId} showAccounts={true} />
      )}
    </>
  )
}

export default GuestDashboard
