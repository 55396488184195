import { TextField } from '@middesk/components'
import React from 'react'
import DropdownBanner from './DropdownBanner'
import Address from '../../components/Address'
import { ADDRESS_TYPES, PO_BOX_PATTERN } from '../../lib/constants'
import { InnerFormColumn } from './index'
import { useFormikContext } from 'formik'
import { ConfirmCompany } from '../../types'
import * as yup from 'yup'

const BusinessInformation = () => {
  const { values } = useFormikContext<ConfirmCompany>()

  return (
    <DropdownBanner
      valid={businessInformationSchema.isValidSync(values)}
      title={'Business information'}
    >
      <InnerFormColumn>
        <TextField
          placeholder='Enter legal business name'
          name='legal_name'
          label='Legal business Name'
          showErrorMessage
        />
        <Address
          name='primary_address'
          label='Primary business address'
          addressType={ADDRESS_TYPES.primary}
          allowPOBox={false}
          showErrorMessage
        />
      </InnerFormColumn>
    </DropdownBanner>
  )
}

export const businessInformationSchema = yup.object().shape({
  legal_name: yup
    .string()
    .required('Legal business name is required')
    .typeError('Legal business name is required'),
  primary_address: yup.object().shape({
    address_line1: yup
      .string()
      .required('Required')
      .test(
        'po_box',
        'Should not be a PO Box',
        (v: any) => !PO_BOX_PATTERN.test(v)
      )
      .typeError('Required'),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().required('Required').typeError('Required'),
    state: yup.string().required('Required').typeError('Required'),
    postal_code: yup.string().required('Required').typeError('Required'),
    type: yup.string().optional()
  })
})

export default BusinessInformation
