import React, { useContext } from 'react'

import styled from 'styled-components'

import { theme } from '@middesk/components'

import { AuthContext } from '../../contexts/AuthProvider'
import Page from '../../components/System/Layout/Page'
import Sidebar from '../../components/Sidebar'
import Accounts from './Accounts'

import { GUSTO_ACCOUNT_SLUG } from '../../lib/constants'

const { spacing, typography, colors } = theme

export const GuestPage = styled.div`
  h1 {
    color: ${colors.graphite};
    font-family: ${typography.faces.display};
    font-weight: ${typography.weights.normal};
    font-size: ${typography.sizes.xlarge};
    margin-bottom: ${spacing.xlarge};
  }
`

const StyledGuestSidebar = styled.div`
  margin-left: ${spacing.xxlarge};
`

const SidebarSelection = styled.div`
  font-weight: ${typography.weights.bold};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const GuestDashboard = ({
  companyId,
  showAccounts
}: {
  companyId: string
  showAccounts?: boolean
}): JSX.Element => {
  const { user } = useContext(AuthContext)
  const gustoUser = user?.account?.parent_account?.slug === GUSTO_ACCOUNT_SLUG

  return (
    <Page>
      <Sidebar
        logo={user?.account?.parent_account?.logo}
        largeLogo={gustoUser}
        gustoIntake={gustoUser}
      >
        <StyledGuestSidebar>
          <SidebarSelection>Accounts</SidebarSelection>
        </StyledGuestSidebar>
      </Sidebar>
      {showAccounts && <Accounts companyId={companyId} />}
    </Page>
  )
}

export default GuestDashboard
