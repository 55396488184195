import React from 'react'

import { useHistory } from 'react-router'

import AccountDetails from '../../components/AccountDetails'
import { GuestPage } from '.'

const Accounts = ({ companyId }: { companyId: string }): JSX.Element => {
  const { push } = useHistory()

  const onFilterChange = (newParams: string) => {
    push(`/guest_dashboard/${companyId}?${newParams}`)
  }

  return (
    <GuestPage>
      <h1>Accounts</h1>
      <AccountDetails
        companyId={companyId}
        guestDashboard={true}
        showLocals={true}
        perPage={20}
        onFilterChange={onFilterChange}
      />
    </GuestPage>
  )
}

export default Accounts
