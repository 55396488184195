import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import TaxRegistrationLineItems from '../components/TaxRegistrationLineItems'
import Loader from '../components/System/Loader'
import { Page, PageProps } from '../components/Page'
import { AuthContext } from '../contexts/AuthProvider'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import api from '../lib/api'
import {
  applicationPayload,
  fqLineItemsFromApplication,
  localTaxLineItems
} from '../lib/helpers'
import { FormValues, RegistrationType } from '../types'
import PaymentCapture, { trackUser } from '../components/PaymentCapture'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { MANAGED_BY_MIDDESK } from '../lib/constants'

export const LineItems = styled.div`
  margin: 5px 0;
`

const validationSchema = yup.object().shape({
  accept_tos: yup.bool().oneOf([true], 'You Must Accept Payment Usage Terms')
})

const Payment: Page = ({
  validateFormAndNavigate,
  onCancel,
  updateValidationSchema,
  showSOSPages,
  pricingData,
  application,
  isSubmitting = false,
  showPoweredByMiddesk,
  progress,
  localJurisdictionRegistrations
}: PageProps) => {
  const { values, submitForm } = useFormikContext<FormValues>()
  const { internal, transactionalAccount } = useContext(AuthContext)
  const {
    questions,
    state,
    taxTypesByIntent,
    localJurisdictionsInState,
    localQuestionsMap
  } = useContext(ApplicationContext)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [paymentReady, setPaymentReady] = useState(false)
  const [updatingApplication, setUpdatingApplication] = useState(false)
  const [error, setError] = useState('')
  const [newUser, setNewUser] = useState(false)
  const [setupSecret, setSetupSecret] = useState('')
  const isSubmitPage = !showSOSPages

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)

    if (internal) {
      setLoading(false)
      return
    }

    api
      .get('/ajax/setup_card')
      .then(json => setSetupSecret(json.client_secret))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (application?.company_id) {
      api
        .get(`/v1/agent/companies/${application.company_id}/tax_registrations`)
        .then(json => setNewUser(json.data && json.data.length === 0))
    }
  }, [application])

  const applicationUpdate = () => {
    if (updatingApplication) {
      return
    }

    if (showSOSPages) {
      if (!internal) {
        trackUser(newUser, 'new-application')
      }

      return validateFormAndNavigate({ values, submit: false })
    }

    setUpdatingApplication(true)

    if (application?.id) {
      api
        .put(`/v1/agent/applications/${application.id}`, {
          ...applicationPayload(values, questions, state, localQuestionsMap),
          submit: isSubmitPage
        })
        .then(() => {
          if (!internal) {
            trackUser(newUser, 'new-application')
          }

          const { redirect_uri } = application

          if (redirect_uri) {
            window.location.href = redirect_uri as string
          }

          submitForm().then(() => {
            setSubmitting(false)
            setUpdatingApplication(false)
          })
        })
        .catch((e: any) => {
          if (e.json && e.json.errors) {
            const errors = e.json.errors
              .map((error: any) => error.message)
              .join(', ')
            Sentry.captureException(new Error(errors))
            setError(errors)
          } else {
            Sentry.captureException(e)
            setError('Something went wrong. Please try again')
          }
          setSubmitting(false)
          setUpdatingApplication(false)
        })
    }
  }

  const taxRegistrationLineItems = () => {
    if (transactionalAccount && pricingData?.tax_registration_current_prices) {
      const managedPricingData = pricingData?.tax_registration_current_prices[
        state
      ].filter(data =>
        (taxTypesByIntent[MANAGED_BY_MIDDESK] || []).includes(
          data['type'] as RegistrationType
        )
      )

      const localPricingData = localTaxLineItems(
        pricingData,
        localJurisdictionRegistrations,
        localJurisdictionsInState
      )

      const fqPricingData =
        state &&
        values.foreign_qualifications &&
        values.foreign_qualifications[0] &&
        values.foreign_qualifications[0].opted_in
          ? fqLineItemsFromApplication(
              application,
              !!values.foreign_qualifications[0].transfer
            )
          : []

      const registrationPrices = [
        ...managedPricingData,
        ...fqPricingData,
        ...localPricingData
      ]

      return (
        <LineItems>
          <TaxRegistrationLineItems
            taxRegistrationPrices={registrationPrices}
          />
        </LineItems>
      )
    }
  }

  const onClick = () => {
    setSubmitting(true)
  }

  return (
    <>
      <Loader {...{ loading }}>
        <PaymentCapture
          setPaymentReady={setPaymentReady}
          submitting={submitting}
          setSubmitting={setSubmitting}
          applicationUpdate={applicationUpdate}
          error={error}
          setError={setError}
          setupSecret={setupSecret}
          lineItems={taxRegistrationLineItems()}
        />
        <NewRegistrationIntakeFooter
          {...{
            values,
            onCancel,
            isSubmitting: submitting || isSubmitting,
            error,
            isDisabled: !validationSchema.isValidSync(values) || !paymentReady,
            onClick,
            progress,
            title: 'Payment information',
            showPoweredByMiddesk,
            submitText: 'Complete Order'
          }}
        />
      </Loader>
    </>
  )
}

Payment.pageName = 'Payment'
Payment.title = () => 'Payment information'
Payment.description = () =>
  'Enter your billing information to submit your state filing.'

export default Payment
