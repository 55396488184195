import { TextField } from '@middesk/components'
import React from 'react'
import DropdownBanner from './DropdownBanner'
import { InnerFormColumn } from './index'
import { useFormikContext } from 'formik'
import { ConfirmCompany } from '../../types'
import * as yup from 'yup'

const ContactInformation = () => {
  const { values } = useFormikContext<ConfirmCompany>()

  return (
    <DropdownBanner
      valid={contactInformationSchema.isValidSync(values)}
      title={'Contact information'}
    >
      <InnerFormColumn>
        <TextField
          placeholder='Enter the business contact name'
          name='contact_name'
          label='Contact name'
          showErrorMessage
        />
        <TextField
          placeholder='Enter the business contact email'
          name='contact_email'
          label='Contact email'
          showErrorMessage
        />
        <TextField
          placeholder='Enter the business contact phone number'
          name='contact_phone_number'
          label='Contact phone number'
          type='phone'
          showErrorMessage
        />
        <TextField
          placeholder='Enter the business contact title'
          name='contact_title'
          label='Contact title'
          showErrorMessage
        />
      </InnerFormColumn>
    </DropdownBanner>
  )
}

export const contactInformationSchema = yup.object().shape({
  contact_name: yup
    .string()
    .required('Name is required')
    .typeError('Name is required')
    .nullable(),
  contact_email: yup
    .string()
    .email()
    .required('Email is required')
    .typeError('Valid email is required')
    .nullable(),
  contact_phone_number: yup
    .string()
    .required('Phone is required')
    .typeError('Phone is required')
    .nullable(),
  contact_title: yup.string().optional().nullable()
})

export default ContactInformation
