import api from '../api'

export type GetGuestTokenResponse = {
  guest_token: string
}

export const APIGuestToken = {
  get_guest_token: (
    companyId: string,
    enhanced_guest_token: string
  ): Promise<GetGuestTokenResponse> =>
    api.get(`/v1/agent/companies/${companyId}/guest_tokens`, {
      enhanced_guest_token
    })
}
