import {
  JobTitle,
  AllJobTitleKeys,
  JobTitleKeys,
  LLCJobTitleKeys,
  LlcStructureType,
  OrganizationEntityType,
  Packages,
  State
} from '../types'

export const PackageTypes = {
  unlimited: 'unlimited',
  premium: 'premium',
  basic: 'basic'
}

export const PackageVariations: Packages = {
  state_filing: {
    basic: {
      prettyTitle: 'Basic',
      price: 45,
      priceText: '$45 / month'
    },
    premium: {
      prettyTitle: 'Premium',
      price: 75,
      priceText: '$75 / month'
    },
    unlimited: {
      prettyTitle: 'Unlimited',
      price: 15000,
      priceText: '$15,000 / year'
    }
  }
}

export const SIGNATORY_JOB_TITLES = [
  'Chief Executive Officer',
  'President',
  'Vice President'
]

export const ADDRESS_TYPES: Readonly<Record<string, string>> = Object.freeze({
  primary: 'primary',
  mailing: 'mailing',
  remote: 'remote',
  previous_primary: 'previous_primary',
  secondary: 'secondary'
})

export const DIRECTOR_TITLE = 'Director'

export const JOB_TITLES: Readonly<
  Record<JobTitleKeys, JobTitle>
> = Object.freeze({
  CHIEF_EXECUTIVE_OFFICER: 'Chief Executive Officer',
  CHIEF_FINANCIAL_OFFICER: 'Chief Financial Officer',
  CHIEF_OPERATING_OFFICER: 'Chief Operating Officer',
  CHIEF_TECHNOLOGY_OFFICER: 'Chief Technology Officer',
  CHIEF_PRODUCT_OFFICER: 'Chief Product Officer',
  CHIEF_BUSINESS_OFFICER: 'Chief Business Officer',
  CHIEF_ADMINISTRATIVE_OFFICER: 'Chief Administrative Officer',
  PRESIDENT: 'President',
  SECRETARY: 'Secretary',
  SECRETARY_OR_TREASURER: 'Secretary/Treasurer',
  TREASURER: 'Treasurer',
  VICE_PRESIDENT: 'Vice President'
})

export const LLC_JOB_TITLES: Readonly<
  Record<LLCJobTitleKeys, JobTitle>
> = Object.freeze({
  MANAGER: 'Manager',
  MANAGING_MEMBER: 'Managing Member',
  MEMBER: 'Member'
})
export const ALL_JOB_TITLES: Readonly<Record<AllJobTitleKeys, JobTitle>> = {
  ...JOB_TITLES,
  ...LLC_JOB_TITLES
}

export const CORPORATION = 'corporation'
export const C_CORPORATION = 'c_corporation'
export const S_CORPORATION = 's_corporation'
export const LLC = 'llc'
export const NONPROFIT_CORPORATION = 'nonprofit_corporation'
export const NONPROFIT_LLC = 'nonprofit_llc'

export const ENTITY_TYPES: Readonly<
  Record<OrganizationEntityType, string>
> = Object.freeze({
  CORPORATION: CORPORATION,
  LLC: LLC,
  NONPROFIT_CORPORATION: NONPROFIT_CORPORATION,
  NONPROFIT_LLC: NONPROFIT_LLC
})

export const ENTITY_TYPE_LABELS: Readonly<
  Record<OrganizationEntityType, string>
> = Object.freeze({
  CORPORATION: 'Corporation',
  LLC: 'Limited Liability Company',
  NONPROFIT_CORPORATION: 'Nonprofit Corporation',
  NONPROFIT_LLC: 'Nonprofit LLC'
})

export const ENTITY_TYPE_REVIEW_LABELS: Readonly<
  Record<OrganizationEntityType, string>
> = Object.freeze({
  CORPORATION: 'Corporation',
  corporation: 'Corporation',
  LLC: 'Limited Liability Company',
  llc: 'Limited Liability Company',
  NONPROFIT_CORPORATION: 'Nonprofit Corporation',
  nonprofit_corporation: 'Nonprofit Corporation',
  NONPROFIT_LLC: 'Nonprofit LLC',
  nonprofit_llc: 'Nonprofit LLC'
})

export const ENTITY_TYPE_IR_OPTIONS = Object.freeze({
  [CORPORATION]: 'Corporation',
  [LLC]: 'Limited Liability Company',
  [NONPROFIT_CORPORATION]: 'Nonprofit Corporation',
  [NONPROFIT_LLC]: 'Nonprofit LLC'
})

export const LLC_SINGLE_MEMBER = 'SINGLE_MEMBER'
export const LLC_MULTI_MEMBER = 'MULTI_MEMBER'

export const LLC_STRUCTURE_TYPES = [LLC_SINGLE_MEMBER, LLC_MULTI_MEMBER]

export const LLC_STRUCTURES: Readonly<
  Record<LlcStructureType, string>
> = Object.freeze({
  [LLC_SINGLE_MEMBER]: 'Single-Member',
  [LLC_MULTI_MEMBER]: 'Multi-Member'
})

export const SINGLE_MEMBER_TAXED_AS_TYPE = 'individual'
export const MULTI_MEMBER_TAXED_AS_TYPE = 'partnership'

export const TAXED_AS_ENTITY_TYPE_LABELS: Readonly<
  Record<string, string>
> = Object.freeze({
  [C_CORPORATION]: 'C-Corporation',
  [S_CORPORATION]: 'S-Corporation',
  [SINGLE_MEMBER_TAXED_AS_TYPE]: 'Individual (Disregarded Entity)',
  [MULTI_MEMBER_TAXED_AS_TYPE]: 'Partnership'
})

export const LLC_PREFIX = 'llc'
export const LLC_LONG = 'limited liability company'

export const ACQUIRED_REGISTERED = 'ACQUIRED_REGISTERED'
export const CHANGED_ENTITY_TYPE = 'CHANGED_ENTITY_TYPE'

export const UNSUPPORTED_REGISTRATION_REASONS = [
  ACQUIRED_REGISTERED,
  CHANGED_ENTITY_TYPE
]

export const REGISTRATION_REASONS: Readonly<
  Record<string, string>
> = Object.freeze({
  FIRST_EMPLOYEE: 'Hired first employee in state',
  NEW_BUSINESS: 'Started a new business',
  CHANGED_PEO: 'Changed PEO',
  [ACQUIRED_REGISTERED]: 'Acquired previously registered business',
  [CHANGED_ENTITY_TYPE]: 'Changed legal entity type'
})

export const ACCOUNTING_BASIS_OPTIONS: Readonly<
  Record<string, string>
> = Object.freeze({
  CASH: 'Cash',
  ACCRUAL: 'Accrual',
  OTHER: 'Other'
})

export const PAYROLL_FREQUENCY_OPTIONS: Readonly<
  Record<string, string>
> = Object.freeze({
  WEEKLY: 'Weekly',
  EVERY_OTHER_WEEK: 'Every other week',
  SEMIMONTHLY: 'Semimonthly',
  MONTHLY: 'Monthly'
})

export const PO_BOX_PATTERN =
  // https://stackoverflow.com/questions/5680050/po-box-regular-expression-validation
  // eslint-disable-next-line no-useless-escape
  /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i

export const PO_BOX_TEST = 'po_box'
export const PO_BOX_TEST_MESSAGE = 'Should not be a PO Box'

export const STATES: Readonly<State[]> = Object.freeze([
  {
    name: 'Alabama',
    abbr: 'AL'
  },
  {
    name: 'Alaska',
    abbr: 'AK'
  },
  {
    name: 'Arizona',
    abbr: 'AZ'
  },
  {
    name: 'Arkansas',
    abbr: 'AR'
  },
  {
    name: 'California',
    abbr: 'CA'
  },
  {
    name: 'Colorado',
    abbr: 'CO'
  },
  {
    name: 'Connecticut',
    abbr: 'CT'
  },
  {
    name: 'Delaware',
    abbr: 'DE'
  },
  {
    name: 'District of Columbia',
    abbr: 'DC'
  },
  {
    name: 'Florida',
    abbr: 'FL'
  },
  {
    name: 'Georgia',
    abbr: 'GA'
  },
  {
    name: 'Hawaii',
    abbr: 'HI'
  },
  {
    name: 'Idaho',
    abbr: 'ID'
  },
  {
    name: 'Illinois',
    abbr: 'IL'
  },
  {
    name: 'Indiana',
    abbr: 'IN'
  },
  {
    name: 'Iowa',
    abbr: 'IA'
  },
  {
    name: 'Kansas',
    abbr: 'KS'
  },
  {
    name: 'Kentucky',
    abbr: 'KY'
  },
  {
    name: 'Louisiana',
    abbr: 'LA'
  },
  {
    name: 'Maine',
    abbr: 'ME'
  },
  {
    name: 'Maryland',
    abbr: 'MD'
  },
  {
    name: 'Massachusetts',
    abbr: 'MA'
  },
  {
    name: 'Michigan',
    abbr: 'MI'
  },
  {
    name: 'Minnesota',
    abbr: 'MN'
  },
  {
    name: 'Mississippi',
    abbr: 'MS'
  },
  {
    name: 'Missouri',
    abbr: 'MO'
  },
  {
    name: 'Montana',
    abbr: 'MT'
  },
  {
    name: 'Nebraska',
    abbr: 'NE'
  },
  {
    name: 'Nevada',
    abbr: 'NV'
  },
  {
    name: 'New Hampshire',
    abbr: 'NH'
  },
  {
    name: 'New Jersey',
    abbr: 'NJ'
  },
  {
    name: 'New Mexico',
    abbr: 'NM'
  },
  {
    name: 'New York',
    abbr: 'NY'
  },
  {
    name: 'North Carolina',
    abbr: 'NC'
  },
  {
    name: 'North Dakota',
    abbr: 'ND'
  },
  {
    name: 'Ohio',
    abbr: 'OH'
  },
  {
    name: 'Oklahoma',
    abbr: 'OK'
  },
  {
    name: 'Oregon',
    abbr: 'OR'
  },
  {
    name: 'Pennsylvania',
    abbr: 'PA'
  },
  {
    name: 'Rhode Island',
    abbr: 'RI'
  },
  {
    name: 'South Carolina',
    abbr: 'SC'
  },
  {
    name: 'South Dakota',
    abbr: 'SD'
  },
  {
    name: 'Tennessee',
    abbr: 'TN'
  },
  {
    name: 'Texas',
    abbr: 'TX'
  },
  {
    name: 'Utah',
    abbr: 'UT'
  },
  {
    name: 'Vermont',
    abbr: 'VT'
  },
  {
    name: 'Virginia',
    abbr: 'VA'
  },
  {
    name: 'Washington',
    abbr: 'WA'
  },
  {
    name: 'West Virginia',
    abbr: 'WV'
  },
  {
    name: 'Wisconsin',
    abbr: 'WI'
  },
  {
    name: 'Wyoming',
    abbr: 'WY'
  }
])

export const STATES_WITH_TERRITORIES = STATES.concat([
  {
    name: 'American Samoa',
    abbr: 'AS'
  },
  {
    name: 'Guam',
    abbr: 'GU'
  },
  {
    name: 'Marshall Islands',
    abbr: 'MH'
  },
  {
    name: 'Northern Mariana Islands',
    abbr: 'MP'
  },
  {
    name: 'Palau',
    abbr: 'PW'
  },
  {
    name: 'Puerto Rico',
    abbr: 'PR'
  },
  {
    name: 'Virgin Islands',
    abbr: 'VI'
  }
])

export const STATE_MAP: { [key: string]: State } = {}

STATES.reduce((o, state) => {
  o[state.abbr] = state

  return o
}, STATE_MAP)

export const STATE_ABBR_ARRAY: string[] = STATES.map(state => state.abbr)

export const MAIL_TAGS = [
  { key: 'additional_account', value: 'Additional account' },
  { key: 'account_activity', value: 'Account activity' },
  { key: 'account_creation', value: 'Account creation' },
  { key: 'credentialing', value: 'Credentialing' },
  { key: 'filing_notice', value: 'Filing notice' },
  { key: 'missing_information', value: 'Missing information' },
  { key: 'rate_change', value: 'Rate change' },
  { key: 'tax_notice', value: 'Tax notice' },
  { key: 'ui_claim', value: 'UI claim' },
  { key: 'delayed', value: 'Delayed' },
  { key: 'duplicate', value: 'Duplicate' },
  { key: 'other', value: 'Other' }
]

export const OWNERSHIP_TAGS = [
  { key: 'action_required', value: 'Action required', color: 'warning' },
  { key: 'no_action_required', value: 'No action required', color: 'yellow' },
  { key: 'managed_by_middesk', value: 'Managed by Middesk', color: 'green' }
]

export const SUPER_ADMIN = 'super_admin'
export const ADMIN = 'admin'

export const ADMIN_ROLES = [SUPER_ADMIN, ADMIN]

export const ALL = 'All'

export const DEFAULT_PAGE_NUM = 1
export const DEFAULT_PER_PAGE_COUNT = 25

export const CREATED_AT = 'created_at'

export const ANNUAL_FILING_DOCUMENT_TYPE = {
  key: 'annual_filing',
  value: 'Annual report'
}
export const FOREIGN_QUALIFICATION_DOCUMENT_TYPE = {
  key: 'foreign_qualification',
  value: 'Business registration'
}

export const UNMANAGED_FQ_STATUS = 'unmanaged'

export const SECRETARY_OF_STATE = 'Secretary of State'
export const ANNUAL_FILING_MESSAGE =
  "We'll help you file your annual report to ensure your business remains in good standing with the Secretary of State."

export const CUSTOMER_INFORMATION_REQUEST_STATUS =
  'customer_information_request'

export const COMPLETED_STATUS = 'completed'

export const DEFAULT_TRANSFER_FQ_ACTION = 'transfer'
export const DEFAULT_TRANSFER_TR_ACTION = 'transfer'

export const STATES_VALIDATING_AUTHORIZED_SHARES = ['OK']
export const STATES_VALIDATING_AUTHORIZED_AND_ISSUED_SHARES = [
  'AK',
  'AZ',
  'IL',
  'NM',
  'SC',
  'WI'
]
export const STATES_VALIDATING_PAR_VALUE = ['AK', 'IL', 'NV', 'OK', 'RI', 'WI']

export const STATES_REQUIRING_DIRECTOR = [
  'AZ',
  'CT',
  'HI',
  'IL',
  'IA',
  'ME',
  'MT',
  'NV',
  'NH',
  'NM',
  'RI',
  'SC',
  'TN',
  'TX',
  'VT',
  'VA',
  'WV',
  'WI'
]

export const STATES_REQUIRING_LLC_OWNER = [
  'AK',
  'AR',
  'CT',
  'GA',
  'HI',
  'IL',
  'IA',
  'LA',
  'ME',
  'MA',
  'MI',
  'MT',
  'NV',
  'NH',
  'ND',
  'RI',
  'SC',
  'UT',
  'WV',
  'WI'
]

export const STATES_REQUIRING_ONE_CORP_PRESIDENT = ['WI']
export const STATES_REQUIRING_ONE_LLC_MEMBER = ['WI']

export const STATES_REQUIRING_100_PERCENT_OWNERSHIP = [
  'DE',
  'ID',
  'KS',
  'NM',
  'NV'
]

export const STATES_REQUIRING_100_PERCENT_OWNERSHIP_OR_NONPROFIT = ['MD']

export const STATES_REQUIRING_100_PERCENT_OR_THREE_OWNERS_OWNERSHIP = [
  'OH',
  'SC'
]

export const STATES_REQUIRING_TWO_MINIMUM_OFFICERS = ['MD']

export const STATES_REQUIRING_NONPROFIT_THREE_OFFICERS = ['TN', 'MN']

export const STATES_REQUIRING_100_PERCENT_OR_THREE_OFFICERS = ['MN']

export const STATES_REQUIRING_100_PERCENT_OWNERSHIP_FROM_INDIVIDUAL_LLC = ['WI']

export const STATES_REQUIRING_TWO_MINIMUM_LCC_MULTI_MEMBER_OWNERS = ['FL']

export const STATES_REQUIRING_TWO_MINIMUM_LCC_PARTNERSHIP_OFFICERS = [
  'AL',
  'AR',
  'CT',
  'GA',
  'HI',
  'ID',
  'SC',
  'ME',
  'MT',
  'NC',
  'NE',
  'OK',
  'WY'
]

export const AF_STATES_REQUIRING_CEO = [
  'CA',
  'GA',
  'ID',
  'IA',
  'KS',
  'ME',
  'MI',
  'MN',
  'MO',
  'MT',
  'NV',
  'NY',
  'OR'
]

export const AF_STATES_REQUIRING_CFO = ['CA', 'GA', 'IA', 'ME', 'MI', 'NV']

export const AF_STATES_REQUIRING_SECRETARY = [
  'AK',
  'CA',
  'GA',
  'IA',
  'KS',
  'MI',
  'MO',
  'NV',
  'OR'
]

export const AF_STATES_REQUIRING_STOCK_TABLE = ['AR', 'KS', 'MA', 'MS', 'SC']

export const SIGNATURE_CANVAS_HEIGHT = 300

export const GUEST_DASHBOARD_ROUTE = /guest_dashboard/
export const GUEST_APPLICATION_ROUTE = /guest\/applications\/.+/
export const GUEST_TRANSFER_ROUTE = /guest\/transfers\/.+/
export const GUEST_INVITATIONS_ROUTE = /guest\/invitations/
export const DEPRECATED_INVITATIONS_ROUTE = /^\/invitations/
export const APPLICATION_ROUTE = /applications\/.+/
export const SOS_REGISTRATION_ROUTE = /sos_registration\/.+/
export const SOS_CONFIRMATION_ROUTE = /sos_registration_confirmation\/.+/

export const ACTION_TYPES = {
  NEW_APPLICATION: 'new_application',
  TRANSFER_APPLICATION: 'transfer_application',
  INFO_REQUEST: 'info_request',
  ANNUAL_FILING: 'annual_filing'
}

export const APPLICATION_ACTION_TYPES = [
  ACTION_TYPES.NEW_APPLICATION,
  ACTION_TYPES.TRANSFER_APPLICATION
]

export const STATE_TAX_WITHHOLDINGS_TYPE = 'state_tax_withholdings'
export const STATE_UNEMPLOYMENT_INSURANCE_TYPE = 'state_unemployment_insurance'
export const BOTH_SUI_AND_SWH_TYPE = 'both_sui_and_swh'
export const FOREIGN_QUALIFICATION_TYPE = 'foreign_qualification'

export const PAYROLL_REGISTRATION_TYPES = [
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  BOTH_SUI_AND_SWH_TYPE
]

export const SECRETARY_OF_STATE_TYPE = 'secretary_of_state'

export const INFORMATION_REQUESTED = 'information_requested'
export const BLOCKED_BY_GOVERNMENT = 'blocked_by_government'
export const MIDDESK_PROCESSING = 'middesk_processing'
export const GOVERNMENT_PROCESSING = 'government_processing'
export const DUPLICATE = 'duplicate'
export const COMPLETED = 'completed'
export const UNMANAGED = 'unmanaged'
export const NOT_INCLUDED = 'not_included'

export const TAX_AGENCY_REGISTRATION_STATUSES = [
  INFORMATION_REQUESTED,
  BLOCKED_BY_GOVERNMENT,
  MIDDESK_PROCESSING,
  GOVERNMENT_PROCESSING,
  DUPLICATE,
  COMPLETED,
  UNMANAGED,
  NOT_INCLUDED
]

export const PAYROLL_DATE = 'payroll_date'
export const HIRE_DATE = 'hire_date'
export const LIABILITY = 'liability'
export const PORTAL_UNAVAILABLE = 'portal_unavailable'

export const SANDBOX_DENYLIST = [
  '/v1/users',
  '/users',
  '/password',
  '/sessions',
  '/ajax/accounts',
  '/ajax/setup_card'
]

export const MOBILE_BUTTON_HEIGHT = '45px'

export const SIGNATURE_EXCEPTION_ITEM_TYPE = 'signature'
export const DOCUMENT_EXCEPTION_ITEM_TYPE = 'document'
export const ADDRESS_EXCEPTION_ITEM_TYPE = 'address'

export const SECRETARY_ROLES = ['Secretary/Treasurer', 'Secretary']
export const TREASURER_ROLES = [
  'Chief Financial Officer',
  'Secretary/Treasurer',
  'Treasurer'
]
export const PRESIDENT_SECRETARY_TREASURER_ROLES = [
  'President',
  'Vice President',
  'Secretary',
  'Treasurer'
]

export const OWNERSHIP_ERROR_MESSAGE =
  'Total ownership percentage across all owners cannot exceed 100%'

export const AGENCY_DELIVERY_METHOD_MAIL = 'mail'
export const AGENCY_DELIVERY_METHOD_ONLINE_PORTAL = 'online_portal'
export const AGENCY_DELIVERY_METHOD_EMAIL = 'email'
export const AGENCY_DELIVERY_METHOD_IMMEDIATELY = 'immediately'
export const AGENCY_DELIVERY_METHOD_MAIL_AND_EMAIL = 'mail_and_email'

export const FREQUENCY_OPTIONS = [
  'Accelerated',
  'Partial Weekly',
  'Semiweekly',
  'Weekly',
  'Semimonthly',
  'Monthly',
  'Quarterly',
  'Semiannually',
  'Annually',
  'Depositary',
  'Next Day'
]

export const MANAGED_BY_MIDDESK = 'MANAGED_BY_MIDDESK'
export const UNMANAGED_BY_MIDDESK = 'UNMANAGED_BY_MIDDESK'

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const INVALID_SSNS = ['111111111', '123456789', '333333333', '666666666']

export const AGENT_INDIVIDUAL = 'agent_individual'
export const AGENT_BUSINESS = 'agent_business'
export const AGENT_TAX_AGENCY_REGISTRATION = 'agent_tax_agency_registration'
export const AGENT_AGENCY_REGISTRATION = 'agent_agency_registration'

export const UI_JURISDICTION_SLUG_IDENTIFIER = '_state_unemployment_tax'
export const WH_JURISDICTION_SLUG_IDENTIFIER = '_state_tax_withholdings'

export const GUSTO_ACCOUNT_SLUG = 'gusto-str'
export const PAYCHEX_ACCOUNT_SLUG = 'paychex'

export const FQ_STATE_FEES_MESSAGE =
  'Foreign Qualification pricing includes fees imposed by the Secretary of State. These state fees can vary based on company structure, size, and stock information. We do our best to accurately estimate the state fees, but will not know the exact total you will be billed until submission to the state.'
