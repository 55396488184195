import { Button, Icon, theme } from '@middesk/components'
import React, { useState } from 'react'
import DropdownBanner from './DropdownBanner'
import styled from 'styled-components'
import { FieldArray, useFormikContext } from 'formik'
import { AgentBusiness, ConfirmCompany, Individual } from '../../types'
import * as yup from 'yup'
import moment from 'moment'
import { intersection, isEmpty } from 'lodash'
import { Body } from '../../components/System/Typography'
import EntityList, { EntityListItem } from '../../components/EntityList'
import IndividualCard from '../IndividualCard'
import EditingIndividual from './EditingIndividual'
import { individualSchema } from '../EditingIndividual'
import { agentBusinessSchema } from '../EditingBusiness'
import { Attribute } from '../ReviewAndFile'
import {
  AF_STATES_REQUIRING_CEO,
  AF_STATES_REQUIRING_CFO,
  AF_STATES_REQUIRING_SECRETARY,
  AGENT_BUSINESS,
  AGENT_INDIVIDUAL,
  OWNERSHIP_ERROR_MESSAGE
} from '../../lib/constants'
import {
  hasCeoPresidentOrVP,
  hasSignatory,
  isLlcEntityType,
  getTotalOwnershipPercentage,
  hasCFO,
  hasCeoOrPresident,
  hasSecretary
} from '../../lib/helpers'
import { newIndividual } from '../Leadership'

const { spacing, typography, colors } = theme

const BusinessLabel = styled.div`
  margin-bottom: ${spacing.large};
  font-size: ${typography.sizes.medium};
`

const BusinessAttribute = styled.div`
  margin-bottom: ${spacing.compact};
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StartDiv = styled.div`
  align-self: start;
  display: flex;
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.xsmall};
`

const RequirementRow = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${spacing.xsmall};
`

const RequirementColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xsmall};
  margin-bottom: ${spacing.xxsmall};
  margin-top: ${spacing.xxsmall};
`

const RequirementLabel = styled.div<{ satisfied: boolean }>`
  color: ${props => (props.satisfied ? colors.graphite : colors.red)};
  display: flex;
  flex-wrap: wrap;
  font-size: ${typography.sizes.medium};
`

const RequirementIcon = styled.div`
  display: flex;

  svg {
    width: 18px;
    height: 18px;
  }
`

export type Requirement = {
  satisfied: boolean
  label: string
}

const leadershipRequirements = (values: ConfirmCompany): Requirement[] => {
  const { states, entity_type, owners } = values
  const requirements: Requirement[] = []

  requirements.push({
    satisfied: getTotalOwnershipPercentage(owners as any[]) <= 100,
    label: OWNERSHIP_ERROR_MESSAGE
  })

  requirements.push({
    satisfied: hasSignatory(owners),
    label: 'Include at least one signatory'
  })
  if (!isLlcEntityType(entity_type)) {
    if (!isEmpty(intersection(states, AF_STATES_REQUIRING_CEO))) {
      requirements.push({
        satisfied: hasCeoOrPresident(owners as any[]),
        label: `Must provide Chief Executive Officer or President information [${intersection(
          states,
          AF_STATES_REQUIRING_CEO
        ).join(', ')}]`
      })
    }
    if (!isEmpty(intersection(states, AF_STATES_REQUIRING_CFO)))
      requirements.push({
        satisfied: hasCFO(owners as any[]),
        label: `Must provide Chief Financial Officer or Treasurer information [${intersection(
          states,
          AF_STATES_REQUIRING_CFO
        ).join(', ')}]`
      })
    if (states?.includes('AK'))
      requirements.push({
        satisfied: hasCeoPresidentOrVP(owners),
        label: 'Must provide CEO, President, or Vice president information [AK]'
      })

    if (!isEmpty(intersection(states, AF_STATES_REQUIRING_SECRETARY)))
      requirements.push({
        satisfied: hasSecretary(owners as any[]),
        label: `Must provide Secretary information [${intersection(
          states,
          AF_STATES_REQUIRING_SECRETARY
        ).join(', ')}]`
      })
  }

  return requirements
}

export const requirementsComponent = (requirements: Requirement[]) => (
  <>
    <Body>
      <b>Officer / Owner requirements</b>
    </Body>
    <RequirementColumn>
      {requirements.map(({ satisfied, label }) => (
        <RequirementRow key={label}>
          <RequirementIcon>
            <Icon
              name={satisfied ? 'check' : 'cross2'}
              color={satisfied ? colors.green : colors.red}
            />
          </RequirementIcon>
          <RequirementLabel satisfied={satisfied}>{label}</RequirementLabel>
        </RequirementRow>
      ))}
    </RequirementColumn>
  </>
)

const Individuals = ({
  setEditingIndividuals
}: {
  setEditingIndividuals: (editing: boolean) => void
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched
  } = useFormikContext<ConfirmCompany>()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [editing, setEditing] = useState(false)

  const onCancelIndividual = (e: React.MouseEvent, arrayHelpers: any) => {
    e.preventDefault()
    if (!editing) {
      arrayHelpers.remove(editingIndex)
    }
    setEditingIndex(null)
    setFieldTouched('owners')
    setFieldValue(`editing_owner`, null)
    setEditing(false)
    setEditingIndividuals(false)
  }

  const onAddIndividual = () => {
    setEditingIndex(null)
    setFieldValue(`owners[${editingIndex}]`, {
      ...values.editing_owner,
      object: AGENT_INDIVIDUAL
    })
    setFieldValue(`editing_owner`, null)
    setEditing(false)
    setEditingIndividuals(false)
  }

  const handleEdit = (data: Individual, index: number) => {
    setEditingIndividuals(true)
    setEditing(true)
    setEditingIndex(index)
    setFieldValue('editing_owner', values.owners && values.owners[index])
    setFieldValue('editing_owner.titles', data.titles)
    setFieldValue('editing_owner.dob', moment(data.dob).format('MM/DD/YYYY'))
    if (data.hired_date) {
      setFieldValue(
        'editing_owner.hired_date',
        moment(data.hired_date).format('MM/DD/YYYY')
      )
    }

    // Pre-filling signature, fill and mark authorized
    if (data?.signature) {
      setFieldValue(`editing_owner.signature`, data.signature || undefined)
      setFieldValue(
        `editing_owner.authorized`,
        data.authorized === undefined ? true : data.authorized
      )
    }
  }

  const createIndividual = (arrayHelpers: any) => {
    arrayHelpers.insert(values.owners?.length || 0, newIndividual())

    setEditingIndividuals(true)
    setFieldValue('editing_owner', newIndividual())
    setEditingIndex(values.owners?.length || 0)
  }

  const BusinessDisplay = ({ business }: { business: AgentBusiness }) => {
    return (
      <div>
        <BusinessLabel>{business.legal_name}</BusinessLabel>

        {business.dba_name && (
          <BusinessAttribute>
            <Attribute label='DBA name'>{business.dba_name}</Attribute>
          </BusinessAttribute>
        )}

        <BusinessAttribute>
          <Attribute label='Contact email'>
            {business.contact_email || ''}
          </Attribute>
        </BusinessAttribute>

        <BusinessAttribute>
          <Attribute label='Ownership percentage'>
            {business.ownership_percentage}%
          </Attribute>
        </BusinessAttribute>
      </div>
    )
  }

  return (
    <DropdownBanner
      valid={individualsSchema.isValidSync(values)}
      title={'Individuals'}
    >
      <FieldArray
        name='owners'
        render={(arrayHelpers: any) => {
          return (
            <>
              <FlexDiv>
                <div>
                  {requirementsComponent(leadershipRequirements(values))}
                </div>
                <StartDiv>
                  <Button
                    type='secondary'
                    onClick={(e: React.MouseEvent) =>
                      editingIndex == null
                        ? createIndividual(arrayHelpers)
                        : onCancelIndividual(e, arrayHelpers)
                    }
                  >
                    {editingIndex == null ? 'Add an individual' : 'Cancel'}
                  </Button>
                </StartDiv>
              </FlexDiv>

              {editingIndex !== null ? (
                <EditingIndividual
                  onAddIndividual={() => onAddIndividual()}
                  isEditing={editing}
                />
              ) : (
                <>
                  <EntityList>
                    {(values.owners || []).map((data, index) => {
                      const editOption = {
                        onEdit: () => handleEdit(data as Individual, index)
                      }

                      if (data.object === AGENT_BUSINESS) {
                        return (
                          <EntityListItem
                            key={index}
                            onRemove={() => {
                              if (index === 0 && values.owners?.length === 1) {
                                setFieldValue('owners', [])
                              } else {
                                arrayHelpers.remove(index)
                              }
                              setFieldTouched('owners')
                            }}
                          >
                            <BusinessDisplay business={data} />
                          </EntityListItem>
                        )
                      }

                      return (
                        <EntityListItem
                          key={index}
                          onRemove={() => {
                            if (index === 0 && values.owners?.length === 1) {
                              setFieldValue('owners', [])
                            } else {
                              arrayHelpers.remove(index)
                            }
                            setFieldTouched('owners')
                          }}
                          {...editOption}
                        >
                          <IndividualCard index={index} />
                        </EntityListItem>
                      )
                    })}
                  </EntityList>
                </>
              )}
            </>
          )
        }}
      />
    </DropdownBanner>
  )
}

const ownerSchema = yup.object().when('object', {
  is: object => object === AGENT_INDIVIDUAL,
  then: individualSchema,
  else: agentBusinessSchema
})

export const individualsSchema = yup.object().shape({
  owners: yup
    .array()
    .of(ownerSchema)
    .min(1, 'You must enter at least one individual')
    .test(
      'requireSignatory',
      'Must have at least one signatory',
      (owners: any) => hasSignatory(owners)
    )
    .test(
      'requireOwnershipLessThan100',
      OWNERSHIP_ERROR_MESSAGE,
      (owners: any) => getTotalOwnershipPercentage(owners) <= 100
    )
    .when('states', {
      is: states => !isEmpty(intersection(states, AF_STATES_REQUIRING_CEO)),
      then: yup.array().when('entity_type', {
        is: entity_type => !isLlcEntityType(entity_type),
        then: yup
          .array()
          .test(
            'requireCEO',
            'Must provide Chief Executive Officer or President information',
            (owners: any) => {
              return hasCeoOrPresident(owners)
            }
          )
      })
    })
    .when('states', {
      is: states => !isEmpty(intersection(states, AF_STATES_REQUIRING_CFO)),
      then: yup.array().when('entity_type', {
        is: entity_type => !isLlcEntityType(entity_type),
        then: yup
          .array()
          .test(
            'requireCFO',
            'Must provide Chief Financial Officer or Treasurer information',
            (owners: any) => {
              return hasCFO(owners)
            }
          )
      })
    })
    .when('states', {
      is: states =>
        !isEmpty(intersection(states, AF_STATES_REQUIRING_SECRETARY)),
      then: yup.array().when('entity_type', {
        is: entity_type => !isLlcEntityType(entity_type),
        then: yup
          .array()
          .test(
            'requireSecretary',
            'Must provide Secretary information',
            (owners: any) => {
              return hasSecretary(owners)
            }
          )
      })
    })
    .when('states', {
      is: states => states?.includes('AK'),
      then: yup.array().when('entity_type', {
        is: entity_type => !isLlcEntityType(entity_type),
        then: yup
          .array()
          .test(
            'requireAlaskaCEORoles',
            'Must provide CEO, President, or Vice president information',
            (owners: any) => {
              return hasCeoPresidentOrVP(owners)
            }
          )
      })
    })
})

export default Individuals
