import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { mapStateAbbreviationToName } from '../components/StateFilter'
import { Page, PageProps } from '../components/Page'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { FormValues } from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { theme } from '@middesk/components'

const { spacing, typography } = theme

const Icon = styled.img`
  height: 100px;
  width: 90px;
  margin: 1rem auto 0;
`

const Heading = styled.div`
  text-align: start;
  font-face: ${typography.faces.display};
  font-size: ${typography.sizes.display.medium};
  font-weight: ${typography.weights.normal};
  margin-bottom: ${spacing.large};
`

const IntroRow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-bottom: ${spacing.large};
`

const SecretaryOfStateIntro: Page = ({
  validateFormAndNavigate,
  showPoweredByMiddesk,
  progress
}: PageProps) => {
  const [submitting, setSubmitting] = useState(false)
  const { state } = useContext(ApplicationContext)
  const { values } = useFormikContext<FormValues>()

  const onClick = () => {
    setSubmitting(true)
    validateFormAndNavigate({ values, submit: false })
  }

  return (
    <>
      <Icon src='/registration-received.svg' />
      <Heading>You&apos;re almost there!</Heading>

      <IntroRow>
        <p>
          Thanks for submitting your tax registration! You need to be registered
          with the <b>{mapStateAbbreviationToName(state)} Secretary of State</b>{' '}
          in order to register for payroll taxes. Please select whether or not
          you&apos;re registered.
        </p>
      </IntroRow>

      <NewRegistrationIntakeFooter
        {...{
          values,
          isSubmitting: submitting,
          isDisabled: false,
          onClick,
          submitText: 'Complete Registration',
          progress,
          title: 'Secretary of State information',
          showPoweredByMiddesk
        }}
      />
    </>
  )
}

SecretaryOfStateIntro.pageName = 'SecretaryOfStateIntro'
SecretaryOfStateIntro.title = () => 'Secretary of State'
SecretaryOfStateIntro.description = () =>
  'Registration with the Secretary of State is required for this state.'

export default SecretaryOfStateIntro
