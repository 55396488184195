import styled from 'styled-components'
import { theme } from '@middesk/components'

const { spacing, colors } = theme

const FilterCollection = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${spacing.small};

  > div > div {
    background-color: ${colors.white} !important;
  }
`

export default FilterCollection
