import { SelectField, TextField, theme } from '@middesk/components'
import { useField, useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import Autocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import styled from 'styled-components'
import { ADDRESS_TYPES, PO_BOX_PATTERN, STATES } from '../lib/constants'
import { FormValues } from '../types'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_SIZES } from './System/Typography'
import { StyledSelectWrapper } from '../pages/CompanyDetails'

export const addressFieldNames = (name: string) => [
  `${name}.address_line1`,
  `${name}.address_line2`,
  `${name}.city`,
  `${name}.state`,
  `${name}.postal_code`,
  `${name}.full_address`,
  `${name}.employee_count`
]

const { spacing } = theme

const Menu = styled.div`
  background-color: white;
  border-bottom: 2px solid ${COLORS.indigo};
  border-left: 2px solid ${COLORS.indigo};
  border-radius: 0 0 4px 4px;
  border-right: 2px solid ${COLORS.indigo};
  position: absolute;
  margin-top: -14px;
  width: 100%;
  z-index: 1;
`

const SpacedTextField = styled.div`
  margin: ${spacing.small} 0;
`

const Suggestion = styled.div`
  color: ${COLORS.karl};
  cursor: pointer;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  padding: 10px 8px;
`

const ActiveSuggestion = styled(Suggestion)`
  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  background-color: ${COLORS.frost};
  color: ${COLORS.graphite};
`
const Address = styled(
  ({
    className,
    label,
    name,
    sublabel,
    allowPOBox = true,
    addressType = null,
    validate,
    showErrorMessage,
    showErrorWithoutBlur,
    optional,
    tooltip
  }) => {
    const [field] = useField(name)
    const {
      setFieldValue,
      setFieldTouched
    }: {
      setFieldValue: any
      setFieldTouched: any
    } = useFormikContext<FormValues>()

    useEffect(() => {
      if (addressType) {
        setFieldValue(`${name}.type`, addressType)
      }
    }, [])

    const value = (field.value && field.value.address_line1) || ''
    const onSelect = (address: string, place_id: string) => {
      return geocodeByPlaceId(place_id).then((results: any) => {
        const [result] = results
        const { address_components } = result
        let streetNumber = '',
          route = '',
          city = '',
          state = '',
          postalCode = '',
          postalCodeSuffix = '',
          sublocality = ''

        for (const component of address_components) {
          const { types, long_name, short_name } = component

          if (types.includes('street_number')) {
            streetNumber = short_name
          } else if (types.includes('route')) {
            route = short_name
          } else if (types.includes('locality')) {
            city = long_name
          } else if (types.includes('administrative_area_level_1')) {
            state = short_name
          } else if (types.includes('postal_code')) {
            postalCode = short_name
          } else if (types.includes('postal_code_suffix')) {
            postalCodeSuffix = short_name
          } else if (types.includes('sublocality_level_1')) {
            sublocality = short_name
          }
        }

        if (isEmpty(city)) {
          city = sublocality
        }

        const postalCodeWithSuffix = postalCodeSuffix
          ? `${postalCode}-${postalCodeSuffix}`
          : postalCode

        setFieldValue(`${name}.address_line1`, `${streetNumber} ${route}`)
        setFieldValue(`${name}.address_line2`, '')
        setFieldValue(`${name}.city`, city)
        setFieldValue(`${name}.state`, state)
        setFieldValue(`${name}.postal_code`, postalCodeWithSuffix)
        setFieldValue(
          `${name}.full_address`,
          `${streetNumber} ${route} ${city}, ${state} ${postalCodeWithSuffix}`.trim()
        )
      })
    }

    return (
      <div {...{ className }}>
        <Autocomplete
          value={value}
          onChange={value => {
            setFieldValue(`${name}.address_line1`, value)
            setFieldTouched(`${name}.address_line1`)
          }}
          onSelect={onSelect}
          searchOptions={{ types: ['address'] }}
          googleCallbackName='__gapisPlacesInit'
        >
          {({ suggestions, getSuggestionItemProps, getInputProps }: any) => {
            return (
              <div>
                <TextField
                  autoComplete='middesk-address_line1'
                  spellCheck='off'
                  placeholder='Street address'
                  name={`${name}.address_line1`}
                  label={optional ? `${label} (optional)` : label}
                  sublabel={sublabel}
                  validate={validate}
                  className={className}
                  showErrorMessage={showErrorMessage}
                  showErrorWithoutBlur={showErrorWithoutBlur}
                  tooltip={tooltip}
                  {...getInputProps()}
                />
                {suggestions.length > 0 && (
                  <Menu>
                    {suggestions.reduce(
                      (result: Array<any>, suggestion: any) => {
                        if (
                          allowPOBox ||
                          !PO_BOX_PATTERN.test(suggestion.description)
                        ) {
                          const MenuItem = suggestion.active
                            ? ActiveSuggestion
                            : Suggestion

                          result.push(
                            <MenuItem
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion)}
                            >
                              <span>{suggestion.description}</span>
                            </MenuItem>
                          )
                        }

                        return result
                      },
                      []
                    )}
                  </Menu>
                )}
              </div>
            )
          }}
        </Autocomplete>
        <TextField
          autoComplete='middesk-address_line2'
          spellCheck={false}
          placeholder='Apartment, suite, etc. (optional)'
          name={`${name}.address_line2`}
        />
        <TextField
          autoComplete='middesk-city'
          spellCheck={false}
          placeholder='City'
          name={`${name}.city`}
          showErrorMessage={showErrorMessage}
          showErrorWithoutBlur={showErrorWithoutBlur}
        />
        <StyledSelectWrapper>
          <SelectField
            placeholder='State'
            autoComplete='middesk-state'
            spellCheck={false}
            name={`${name}.state`}
            value={
              field.value?.state
                ? { value: field.value.state, label: field.value.state }
                : null
            }
            onChange={(option: any) =>
              setFieldValue(`${name}.state`, option.value)
            }
            validate={validate}
            showErrorMessage={showErrorMessage}
            showErrorWithoutBlur={showErrorWithoutBlur}
          >
            {STATES.map(s => {
              return <option key={s.abbr} value={s.abbr} label={s.abbr} />
            })}
          </SelectField>
        </StyledSelectWrapper>
        <TextField
          autoComplete='middesk-postal_code'
          spellCheck={false}
          placeholder='ZIP code'
          name={`${name}.postal_code`}
          validate={validate}
          showErrorMessage={showErrorMessage}
          showErrorWithoutBlur={showErrorWithoutBlur}
        />
        {addressType === ADDRESS_TYPES.secondary && (
          <SpacedTextField>
            <TextField
              label='How many employees do you have at the above location?'
              placeholder='Enter number of employees at the above location'
              name={`${name}.employee_count`}
              type='number'
              // we intentionally don't validate employee count here
            />
          </SpacedTextField>
        )}
      </div>
    )
  }
)`
  position: relative;
`

export default Address
