import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { FieldArray, useFormikContext } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import { theme } from '@middesk/components'

import Button from '../components/Button'
import EntityList, { EntityListItem } from '../components/EntityList'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { Page, PageProps } from '../components/Page'
import { MAX_WIDTH_MOBILE } from '../components/Body'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import {
  STATES_REQUIRING_100_PERCENT_OWNERSHIP,
  STATES_REQUIRING_100_PERCENT_OR_THREE_OWNERS_OWNERSHIP,
  STATES_REQUIRING_100_PERCENT_OR_THREE_OFFICERS,
  STATES_REQUIRING_100_PERCENT_OWNERSHIP_FROM_INDIVIDUAL_LLC,
  STATES_REQUIRING_DIRECTOR,
  STATES_REQUIRING_LLC_OWNER,
  STATES_REQUIRING_TWO_MINIMUM_LCC_PARTNERSHIP_OFFICERS,
  STATES_REQUIRING_TWO_MINIMUM_LCC_MULTI_MEMBER_OWNERS,
  MOBILE_BUTTON_HEIGHT,
  OWNERSHIP_ERROR_MESSAGE,
  STATES_REQUIRING_TWO_MINIMUM_OFFICERS,
  STATES_REQUIRING_100_PERCENT_OWNERSHIP_OR_NONPROFIT,
  STATES_REQUIRING_NONPROFIT_THREE_OFFICERS,
  STATES_REQUIRING_ONE_CORP_PRESIDENT,
  STATES_REQUIRING_ONE_LLC_MEMBER,
  MULTI_MEMBER_TAXED_AS_TYPE,
  SINGLE_MEMBER_TAXED_AS_TYPE,
  LLC_SINGLE_MEMBER,
  AGENT_INDIVIDUAL,
  AGENT_BUSINESS,
  JOB_TITLES,
  LLC_MULTI_MEMBER
} from '../lib/constants'
import { FormValues, Individual, Owner } from '../types'
import { individualSchema } from './EditingIndividual'
import { OwnerCard } from './IndividualCard'
import {
  Requirement,
  requirementsComponent
} from './ConfirmInformation/Individuals'
import {
  has100PercentOrThreeOfficers,
  has100PercentOrThreeOwners,
  has100PercentOwnership,
  hasDirector,
  hasLlcOwner,
  hasMinimumTwoOfficers,
  hasMinimumTwoOwners,
  getTotalOwnershipPercentage,
  hasCFO,
  hasCeoOrPresident,
  hasSecretary,
  hasSignatory,
  hasOnlyOnePresident,
  hasOnlyOneLLCMember,
  isLlcEntityType,
  individualOwners,
  hasThreeOfficers,
  hasTitle,
  hasAtLeastOneOwnerWithOwnership,
  hasAtLeastTwoOwnersWithOwnership,
  hasPresidentSecretaryOrTreasurer
} from '../lib/helpers'
import { agentBusinessSchema } from './EditingBusiness'
import EditingOwner, { editingOwnerSchema } from './EditingOwner'
import { isEmpty } from 'lodash'

const { spacing } = theme

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const StartDiv = styled.div`
  display: flex;
  align-self: start;
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.xsmall};

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    justify-content: center;
    width: 100%;
  }
`

const MobileButton = styled(Button)`
  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    width: 100%;
    height: ${MOBILE_BUTTON_HEIGHT};
    max-height: ${MOBILE_BUTTON_HEIGHT};
  }
`

export const newIndividual = () => {
  return {
    name: '',
    email: '',
    address: {},
    titles: [],
    ssn: '',
    signatory: false,
    director: false,
    signature: '',
    hired_date: '',
    authorized: false,
    redacted: false,
    ownership_percentage: undefined,
    object: AGENT_INDIVIDUAL
  }
}

export const newBusiness = () => {
  return {
    legal_name: '',
    dba_name: '',
    contact_email: '',
    ownership_start_date: '',
    address: {},
    ownership_percentage: undefined,
    object: AGENT_BUSINESS
  }
}

const SIGNATORY_REQUIREMENT = 'Include at least one signatory'

const standardRequirements = (owners: Owner[]): Requirement[] => [
  {
    satisfied: getTotalOwnershipPercentage(owners) <= 100,
    label: OWNERSHIP_ERROR_MESSAGE
  },
  {
    satisfied: hasSignatory(owners),
    label: hasSignatory(owners)
      ? SIGNATORY_REQUIREMENT
      : individualOwners(owners || []).length > 0
      ? `${SIGNATORY_REQUIREMENT} (assign by editing officer)`
      : `${SIGNATORY_REQUIREMENT} (assign by adding an individual officer)`
  }
]

const fqRequirements = (
  values: FormValues,
  foreignQualificationOnlyRegistration: boolean,
  state: string
): Requirement[] => {
  const { entity_type, owners } = values
  const requirements: Requirement[] = []

  if (
    !isLlcEntityType(entity_type) &&
    !(!foreignQualificationOnlyRegistration && state == 'CA')
  ) {
    requirements.push({
      satisfied: hasTitle(owners, [
        JOB_TITLES.CHIEF_EXECUTIVE_OFFICER,
        JOB_TITLES.CHIEF_FINANCIAL_OFFICER,
        JOB_TITLES.PRESIDENT
      ]),
      label:
        'Provide Chief Executive Officer, Chief Financial Officer, or President information'
    })
  }

  return requirements
}

const leadershipRequirements = (
  values: FormValues,
  foreignQualificationOnlyRegistration: boolean
): Requirement[] => {
  const {
    entity_type,
    llc_structure,
    taxed_as_entity_type,
    state,
    owners,
    non_profit,
    is_501c3_organization,
    foreign_qualifications
  } = values
  const nonprofit501c3 =
    String(non_profit) === 'true' && String(is_501c3_organization) === 'true'
  const hasFq = !isEmpty(foreign_qualifications)
  const requirements: Requirement[] = standardRequirements(owners)

  if (hasFq) {
    requirements.push(
      ...fqRequirements(values, foreignQualificationOnlyRegistration, state)
    )
  }
  if (foreignQualificationOnlyRegistration) {
    return requirements
  }

  if (STATES_REQUIRING_100_PERCENT_OWNERSHIP.includes(state))
    requirements.push({
      satisfied: has100PercentOwnership(owners),
      label:
        'Total ownership percentage across all owners must equal 100 percent'
    })
  if (
    !non_profit &&
    STATES_REQUIRING_100_PERCENT_OWNERSHIP_OR_NONPROFIT.includes(state)
  )
    requirements.push({
      satisfied: has100PercentOwnership(owners),
      label:
        'Total ownership percentage across all owners must equal 100 percent'
    })
  if (
    !nonprofit501c3 &&
    STATES_REQUIRING_100_PERCENT_OR_THREE_OWNERS_OWNERSHIP.includes(state)
  )
    requirements.push({
      satisfied: has100PercentOrThreeOwners(owners),
      label:
        'Total ownership percentage across all owners must equal 100 percent or you must have at least three separate owning officers'
    })
  if (STATES_REQUIRING_100_PERCENT_OR_THREE_OFFICERS.includes(state))
    requirements.push({
      satisfied: has100PercentOrThreeOfficers(owners),
      label:
        'Total ownership percentage across all owners must equal 100 percent or you must have at least three separate officers'
    })
  if (
    non_profit &&
    STATES_REQUIRING_NONPROFIT_THREE_OFFICERS.includes(state) &&
    !(isLlcEntityType(entity_type) && llc_structure === LLC_SINGLE_MEMBER)
  )
    requirements.push({
      satisfied: hasThreeOfficers(owners),
      label: 'Must have at least three separate officers'
    })

  if (
    !isLlcEntityType(entity_type) &&
    STATES_REQUIRING_DIRECTOR.includes(state)
  )
    requirements.push({
      satisfied: hasDirector(owners),
      label: 'Provide at least one director'
    })
  if (
    isLlcEntityType(entity_type) &&
    STATES_REQUIRING_LLC_OWNER.includes(state)
  )
    requirements.push({
      satisfied: hasLlcOwner(owners),
      label: 'Provide at least one LLC manager or member'
    })

  if (state == 'CA')
    requirements.push({
      satisfied: hasCeoOrPresident(owners),
      label: 'Provide Chief Executive Officer or President information'
    })
  if (!isLlcEntityType(entity_type) && state == 'IL') {
    requirements.push({
      satisfied: hasCFO(owners),
      label: 'Provide Chief Financial Officer or Treasurer information'
    })

    requirements.push({
      satisfied: hasSecretary(owners),
      label: 'Provide Secretary information'
    })
  }

  if (!isLlcEntityType(entity_type) && state == 'ND') {
    requirements.push({
      satisfied: hasPresidentSecretaryOrTreasurer(owners),
      label: 'Provide President, Vice President, Secretary or Treasurer'
    })
  }

  if (isLlcEntityType(entity_type) && state == 'HI') {
    if (llc_structure === LLC_MULTI_MEMBER) {
      requirements.push({
        satisfied: hasAtLeastTwoOwnersWithOwnership(owners),
        label: 'Provide at least two owners with some amount of ownership'
      })
    } else {
      requirements.push({
        satisfied: hasAtLeastOneOwnerWithOwnership(owners),
        label: 'Provide an owner with some amount of ownership'
      })
    }
  }

  if (
    isLlcEntityType(entity_type) &&
    llc_structure === LLC_SINGLE_MEMBER &&
    taxed_as_entity_type === SINGLE_MEMBER_TAXED_AS_TYPE &&
    STATES_REQUIRING_100_PERCENT_OWNERSHIP_FROM_INDIVIDUAL_LLC.includes(state)
  ) {
    requirements.push({
      satisfied: has100PercentOwnership(owners),
      label: 'Total ownership percentage must equal 100 percent'
    })
  }

  if (
    isLlcEntityType(entity_type) &&
    STATES_REQUIRING_ONE_LLC_MEMBER.includes(state)
  ) {
    requirements.push({
      satisfied: hasOnlyOneLLCMember(owners),
      label: 'Provide only one individual with the title: "Member"'
    })
  }

  if (
    !isLlcEntityType(entity_type) &&
    STATES_REQUIRING_ONE_CORP_PRESIDENT.includes(state)
  ) {
    requirements.push({
      satisfied: hasOnlyOnePresident(owners),
      label: 'Provide only one president'
    })
  }

  if (isLlcEntityType(entity_type)) {
    if (llc_structure === LLC_SINGLE_MEMBER) {
      // Single Member LLC
      requirements.push({
        satisfied: individualOwners(owners || []).length === 1,
        label: `Provide only the single LLC member information`
      })
    } else {
      // Multi Member LLC
      if (
        STATES_REQUIRING_TWO_MINIMUM_LCC_MULTI_MEMBER_OWNERS.includes(state)
      ) {
        requirements.push({
          satisfied: hasMinimumTwoOwners(owners),
          label: 'Provide at least two owners'
        })
      }

      if (
        taxed_as_entity_type === MULTI_MEMBER_TAXED_AS_TYPE &&
        STATES_REQUIRING_TWO_MINIMUM_LCC_PARTNERSHIP_OFFICERS.includes(state)
      ) {
        requirements.push({
          satisfied: hasMinimumTwoOfficers(owners),
          label: 'Provide at least two officers'
        })
      }
    }
  } else if (STATES_REQUIRING_TWO_MINIMUM_OFFICERS.includes(state)) {
    requirements.push({
      satisfied: hasMinimumTwoOfficers(owners),
      label: 'Provide at least two officers'
    })
  }

  return requirements
}

const validateOwner = (owner: Owner) => {
  if (owner.object === AGENT_INDIVIDUAL) {
    return individualSchema.isValidSync(owner)
  } else {
    return agentBusinessSchema.isValidSync(owner)
  }
}

const Leadership: Page = ({
  validateFormAndNavigate,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  showPoweredByMiddesk,
  progress,
  foreignQualificationOnlyRegistration
}: PageProps) => {
  const { state } = useContext(ApplicationContext)
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors
  } = useFormikContext<FormValues>()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setFieldValue('state', state)
  }, [])

  useEffect(() => {
    if (editingIndex === null) {
      updateValidationSchema && updateValidationSchema(ownershipSchema)
      setFieldTouched('owners')
    }
  }, [editingIndex])

  const onCancelOwner = (e: React.MouseEvent, arrayHelpers: any) => {
    e.preventDefault()
    if (!editing) {
      arrayHelpers.remove(editingIndex)
    }
    setEditingIndex(null)
    setFieldTouched('owners')
    setFieldValue('editing_owner', null)
    setFieldValue('editing_owner_type', null)
  }

  const [preFillSignature, setPreFillSignature] = useState<string>(
    values.editing_owner?.object === AGENT_INDIVIDUAL
      ? values.editing_owner.signature || ''
      : ''
  )

  const handleEdit = (data: Owner, index: number) => {
    setEditing(true)
    setFieldValue('editing_owner', values.owners[index])
    setFieldValue('editing_owner_type', data.object)
    if (data.object === AGENT_INDIVIDUAL) {
      setFieldValue('editing_owner.titles', data.titles)
      setFieldValue('editing_owner.dob', moment(data.dob).format('MM/DD/YYYY'))
      setFieldValue(
        'editing_owner.hired_date',
        data.hired_date ? moment(data.hired_date).format('MM/DD/YYYY') : ''
      )

      // Pre-filling signature, fill and mark authorized
      if (data?.signature) {
        setFieldValue(`editing_owner.signature`, data.signature || '')
        setFieldValue(
          `editing_owner.authorized`,
          data.authorized === undefined ? true : data.authorized
        )
        setPreFillSignature(data?.signature)
      }
    } else {
      setFieldValue(
        'editing_owner.ownership_start_date',
        data.ownership_start_date
          ? moment(data.ownership_start_date).format('MM/DD/YYYY')
          : ''
      )
    }

    updateValidationSchema && updateValidationSchema(editingOwnerSchema)
    setEditingIndex(index)
  }

  const createOwner = (e: React.MouseEvent, arrayHelpers: any) => {
    e.preventDefault()
    arrayHelpers.insert(values.owners.length, newIndividual())
    setFieldValue('editing_owner', newIndividual())
    setFieldValue('editing_owner_type', AGENT_INDIVIDUAL)
    setEditingIndex(values.owners.length)
    updateValidationSchema && updateValidationSchema(editingOwnerSchema)
  }

  const requirements = leadershipRequirements(
    values,
    foreignQualificationOnlyRegistration
  )

  const incompleteRequirements = requirements.some(
    ({ satisfied }) => !satisfied
  )
  const ownerErrors = errors && (errors['owners'] || []).length > 0

  const formErrorMessage =
    error ||
    (incompleteRequirements
      ? 'Please review officer / ownership requirements'
      : '') ||
    (ownerErrors ? 'Please review validation errors on owners' : '')

  return (
    <>
      <FieldArray
        name='owners'
        render={(arrayHelpers: any) => {
          return (
            <>
              <FlexDiv>
                <div>{requirementsComponent(requirements)}</div>
                <StartDiv>
                  <MobileButton
                    type='secondary'
                    disabled={
                      editingIndex == null &&
                      isLlcEntityType(values.entity_type) &&
                      values.llc_structure === LLC_SINGLE_MEMBER &&
                      individualOwners(values.owners).length > 0
                    }
                    onClick={(e: React.MouseEvent) =>
                      editingIndex == null
                        ? createOwner(e, arrayHelpers)
                        : onCancelOwner(e, arrayHelpers)
                    }
                  >
                    {editingIndex == null ? 'Add an officer / owner' : 'Cancel'}
                  </MobileButton>
                </StartDiv>
              </FlexDiv>

              {editingIndex !== null ? (
                <EditingOwner
                  editingIndex={editingIndex}
                  setEditingIndex={setEditingIndex}
                  preFillSignature={preFillSignature}
                  setPreFillSignature={setPreFillSignature}
                  onCancel={(e: React.MouseEvent) => {
                    onCancelOwner(e, arrayHelpers)
                    onCancel()
                  }}
                  showPoweredByMiddesk={showPoweredByMiddesk}
                  progress={progress}
                />
              ) : (
                <>
                  <EntityList>
                    {(values.owners || []).map((data: Owner, index: number) => {
                      const editOption = (data as Individual).redacted
                        ? {}
                        : { onEdit: () => handleEdit(data, index) }

                      return (
                        <EntityListItem
                          key={index}
                          error={!validateOwner(data)}
                          onRemove={() => {
                            if (index === 0 && values.owners.length === 1) {
                              setFieldValue('owners', [])
                            } else {
                              arrayHelpers.remove(index)
                            }
                            setFieldTouched('owners')
                          }}
                          {...editOption}
                        >
                          <OwnerCard index={index} />
                        </EntityListItem>
                      )
                    })}
                  </EntityList>

                  <NewRegistrationIntakeFooter
                    {...{
                      values,
                      onCancel,
                      isSubmitting,
                      error: formErrorMessage,
                      isDisabled: incompleteRequirements,
                      onClick: () =>
                        validateFormAndNavigate({
                          values,
                          schema: ownershipSchema,
                          submit: false,
                          setFieldTouched
                        }),
                      progress,
                      title: 'Officer / ownership information',
                      showPoweredByMiddesk
                    }}
                  />
                </>
              )}
            </>
          )
        }}
      />
    </>
  )
}

const ownerSchema = yup
  .object()
  .when('object', {
    is: AGENT_INDIVIDUAL,
    then: individualSchema
  })
  .when('object', {
    is: AGENT_BUSINESS,
    then: agentBusinessSchema
  })

export const ownershipSchema = yup.object().shape({
  owners: yup
    .array()
    .of(ownerSchema)
    .test('invalidOwners', 'Some owners are not valid', function () {
      const { parent } = this

      return parent.owners.every((owner: any) => validateOwner(owner))
    })
})

Leadership.pageName = 'Leadership'
Leadership.title = () => 'Officer / Owner summary'
Leadership.description = () =>
  'Confirm that all responsible parties have been added and that all of the required information has been submitted.'

export default Leadership
