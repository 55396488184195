import React, { useEffect, useState } from 'react'
import { SelectField, TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { Page, PageProps, GapWrapper } from '../components/Page'
import { FormValues } from '../types'
import { StyledSelectWrapper } from './CompanyDetails'
import {
  ACCOUNTING_BASIS_OPTIONS,
  PAYROLL_FREQUENCY_OPTIONS
} from '../lib/constants'
import moment from 'moment'

export const validationSchema = yup.object().shape({
  fiscal_year_end: yup
    .date()
    .typeError('Invalid date format')
    .required('Please enter fiscal year end')
    .nullable()
    .test(
      'year',
      'Must be in current year',
      value => !!value && moment(value).year() === moment().year()
    ),
  futa_year: yup
    .string()
    .nullable()
    .typeError(
      'Please indicate if and when the company has ever been deemed liable for FUTA'
    )
    .required(
      'Please indicate if and when the company has ever been deemed liable for FUTA'
    ),
  accounting_basis: yup
    .string()
    .required('Please indicate the accounting basis for the company')
    .typeError('Please indicate the accounting basis for the company')
    .nullable(),
  payroll_frequency: yup
    .string()
    .required('Please indicate the payroll frequency for the company')
    .typeError('Please indicate the payroll frequency for the company')
    .nullable(),
  previous_primary_address: yup.object().shape({
    address_line1: yup.string().optional().nullable(),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().optional().nullable(),
    state: yup.string().optional().nullable(),
    postal_code: yup.string().optional().nullable(),
    type: yup.string().optional()
  })
})

const AdditionalCompanyDetails: Page = ({
  validateFormAndNavigate,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  showPoweredByMiddesk,
  progress
}: PageProps) => {
  const {
    values,
    setFieldValue,
    setFieldTouched
  } = useFormikContext<FormValues>()
  const defaultFutaEligibility = isNaN(Number(values.futa_year))
    ? ''
    : String(values.futa_year) === '0'
    ? 'No'
    : 'Yes'
  const [futaEligible, setFutaEligible] = useState(defaultFutaEligibility)
  const nonprofit501c3 =
    String(values.non_profit) === 'true' &&
    String(values.is_501c3_organization) === 'true'

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)

    if (nonprofit501c3) {
      setFieldValue('futa_year', '0')
    }
  }, [])

  const maxYear = new Date().getFullYear() + 1
  const formationYear = values.formation_date?.split('/')[2]
  const futaYears = []
  if (formationYear == undefined) {
    futaYears.push(
      maxYear.toString(),
      (maxYear - 1).toString(),
      (maxYear - 2).toString(),
      (maxYear - 3).toString(),
      (maxYear - 4).toString()
    )
  } else {
    for (let i = parseInt(formationYear); i <= maxYear; i++) {
      futaYears.push(i.toString())
    }
  }

  const updateFutaEligible = (e: { value: string }) => {
    setFutaEligible(e.value)
    setFieldValue('futa_year', e.value === 'No' ? '0' : undefined)
  }

  return (
    <>
      <GapWrapper>
        <TextField
          label='Fiscal year end date in current year'
          placeholder='MM/DD/YYYY'
          type='date'
          name='fiscal_year_end'
          showErrorMessage
          showErrorWithoutBlur
        />
        {!nonprofit501c3 && (
          <>
            <StyledSelectWrapper>
              <SelectField
                onChange={updateFutaEligible}
                showErrorMessage
                showErrorWithoutBlur
                label='Have you ever filed the IRS Form 940 or are you liable to pay federal unemployment tax?'
                sublabel='FUTA liability is met if: You paid wages of $1,500 or more to employees in any calendar quarter, OR you had one or more employees for at least some part of a day in any 20 or more different weeks during the year. Count all full-time, part-time, and temporary employees.'
              >
                <option
                  key='futa-yes'
                  value='Yes'
                  label='Yes'
                  selected={futaEligible === 'Yes'}
                />
                <option
                  key='futa-no'
                  value='No'
                  label='No'
                  selected={futaEligible === 'No'}
                />
              </SelectField>
            </StyledSelectWrapper>
            {futaEligible === 'Yes' && (
              <StyledSelectWrapper>
                <SelectField
                  name='futa_year'
                  label='When did your FUTA liability begin?'
                  showErrorMessage
                  showErrorWithoutBlur
                >
                  {futaYears.map(year => (
                    <option
                      key={year}
                      value={year}
                      label={year}
                      selected={String(values.futa_year) === year}
                    />
                  ))}
                </SelectField>
              </StyledSelectWrapper>
            )}
          </>
        )}
        <StyledSelectWrapper>
          <SelectField
            name='accounting_basis'
            label='Accounting basis'
            showErrorMessage
            showErrorWithoutBlur
          >
            {Object.keys(ACCOUNTING_BASIS_OPTIONS).map(k => (
              <option
                key={k}
                value={k}
                label={ACCOUNTING_BASIS_OPTIONS[k]}
                selected={values.accounting_basis === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
        <StyledSelectWrapper>
          <SelectField
            name='payroll_frequency'
            label='Payroll frequency'
            showErrorMessage
            showErrorWithoutBlur
          >
            {Object.keys(PAYROLL_FREQUENCY_OPTIONS).map(k => (
              <option
                key={k}
                value={k}
                label={PAYROLL_FREQUENCY_OPTIONS[k]}
                selected={values.payroll_frequency === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
      </GapWrapper>
      <NewRegistrationIntakeFooter
        {...{
          values,
          onCancel,
          isSubmitting,
          error,
          isDisabled: false,
          onClick: () =>
            validateFormAndNavigate({
              values,
              schema: validationSchema,
              submit: false,
              setFieldTouched
            }),
          progress,
          title: 'Business information',
          showPoweredByMiddesk
        }}
      />
    </>
  )
}

AdditionalCompanyDetails.pageName = 'AdditionalCompanyDetails'
AdditionalCompanyDetails.title = () => 'Additional company details'
AdditionalCompanyDetails.description = () =>
  'Please review the information below and edit if required.'

export default AdditionalCompanyDetails
