import React, { useContext, useEffect, useMemo, useState } from 'react'
import amplitude from 'amplitude-js'
import { useFormikContext } from 'formik'
import ReactTooltip from 'react-tooltip'
import {
  AgentApplication,
  Company,
  FormValues,
  LocalRegistrationPageProps,
  Question,
  RegistrationIntakeValidateFormAndNavigateProps,
  RegistrationType,
  TransactionalPricingData
} from '../types'
import { COLORS } from './System/Colors'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { Page } from './Page'
import * as yup from 'yup'
import {
  getTaxTypesByIntent,
  localJurisdictions,
  managedTaxTypesFromTaxRegistrationsInState
} from '../lib/helpers'
import { isEmpty, isEqual } from 'lodash'
import { StateDataContext } from '../contexts/StateDataProvider'
import {
  FOREIGN_QUALIFICATION_TYPE,
  MANAGED_BY_MIDDESK
} from '../lib/constants'
import api from '../lib/api'
import { filterQuestions } from '../pages/DynamicStatePage'

const fqOnlyReg = (managedTaxTypes: RegistrationType[], values: FormValues) =>
  isEmpty(managedTaxTypes) && !isEmpty(values.foreign_qualifications)

const registrationTypes = (
  managedTaxTypes: RegistrationType[],
  values: FormValues
) => {
  const result = managedTaxTypes || []
  if (values.foreign_qualifications && values.foreign_qualifications[0]) {
    result.push(FOREIGN_QUALIFICATION_TYPE)
  }

  return result
}

type PagesManagerProps = {
  CurrentPage: Page
  handleSkipStockTable: (values: FormValues) => void
  setShouldShowDynamicStatePage: React.Dispatch<React.SetStateAction<boolean>>
  updateShouldRenderSOS: (
    foreignQualificationRequired: boolean,
    foreignQualificationOptedIn: boolean
  ) => void
  handlePayrollReportPage: (values: FormValues) => void
  handleLocalJurisdictionRegistrations: (values: FormValues) => void
  application: AgentApplication
  validateFormAndNavigate: (
    props: RegistrationIntakeValidateFormAndNavigateProps
  ) => void
  onCancel: () => void
  updateValidationSchema: (schema: any) => void
  pages: Page[]
  pageNumber: number
  firstLocalPageAt?: number
  localJurisdictionRegistrations?: LocalRegistrationPageProps[]
  error: string | null
  isSubmitting: boolean
  handleSosPaymentInformation: (values: FormValues) => void
  showSOSPaymentInformation: boolean
  pricingData: TransactionalPricingData | undefined
  showSOSPages: boolean
  hasDynamicStatePage: boolean
  pageMap: any
  logo: string | undefined
  validationSchema: yup.ObjectSchema
  gustoIntake: boolean
  setShouldShowAdditionalCompanyDetails: React.Dispatch<
    React.SetStateAction<boolean>
  >
  company?: Company
}

export const PagesManager = ({
  CurrentPage,
  handleSkipStockTable,
  setShouldShowDynamicStatePage,
  updateShouldRenderSOS,
  handlePayrollReportPage,
  handleLocalJurisdictionRegistrations,
  handleSosPaymentInformation,
  showSOSPaymentInformation,
  application,
  pricingData,
  showSOSPages,
  hasDynamicStatePage,
  pageMap,
  validateFormAndNavigate,
  onCancel,
  updateValidationSchema,
  pages,
  pageNumber,
  firstLocalPageAt,
  localJurisdictionRegistrations,
  error,
  isSubmitting,
  logo,
  validationSchema,
  gustoIntake,
  setShouldShowAdditionalCompanyDetails,
  company
}: PagesManagerProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const {
    state,
    localJurisdictionsInState,
    taxTypesByIntent,
    setTaxTypesByIntent,
    setLocalJurisdictionSlugs,
    fetchingQuestions,
    questions
  } = useContext(ApplicationContext)
  const { requiresForeignQualification } = useContext(StateDataContext)

  const [
    foreignQualificationRequired,
    setForeignQualificationRequired
  ] = useState(false)
  const [
    foreignQualificationOnlyRegistration,
    setForeignQualificationOnlyRegistration
  ] = useState(fqOnlyReg(taxTypesByIntent[MANAGED_BY_MIDDESK], values))
  const [filteredStateQuestions, setFilteredStateQuestions] = useState<
    Question[]
  >([])
  const [
    managedRegistrationTypesWithFQ,
    setManagedRegistrationTypesWithFQ
  ] = useState(registrationTypes(taxTypesByIntent[MANAGED_BY_MIDDESK], values))

  useEffect(() => {
    setManagedRegistrationTypesWithFQ(
      registrationTypes(taxTypesByIntent[MANAGED_BY_MIDDESK], values)
    )
  }, [taxTypesByIntent[MANAGED_BY_MIDDESK], values.foreign_qualifications])

  useEffect(() => {
    if (fetchingQuestions) return

    setFilteredStateQuestions(
      filterQuestions(questions, values, managedRegistrationTypesWithFQ)
    )
  }, [fetchingQuestions, questions, values, managedRegistrationTypesWithFQ])

  useEffect(() => {
    handleSkipStockTable(values)
  }, [values?.entity_type, state])

  useEffect(() => {
    setShouldShowDynamicStatePage(
      fetchingQuestions || filteredStateQuestions.length > 0
    )
  }, [fetchingQuestions, filteredStateQuestions.length])

  useEffect(() => {
    setForeignQualificationOnlyRegistration(
      fqOnlyReg(taxTypesByIntent[MANAGED_BY_MIDDESK], values)
    )
  }, [taxTypesByIntent[MANAGED_BY_MIDDESK], values.foreign_qualifications])

  useEffect(() => {
    setShouldShowAdditionalCompanyDetails(!foreignQualificationOnlyRegistration)
  }, [foreignQualificationOnlyRegistration])

  useEffect(() => {
    if (foreignQualificationRequired) {
      if (
        !values.foreign_qualifications.some(
          ({ state: fqState }) => fqState === state
        )
      ) {
        setFieldValue('foreign_qualifications', [{ state, opted_in: false }])
      }
    } else if (
      !values.foreign_qualifications.some(
        ({ state: fqState, opted_in }) => fqState === state && opted_in
      )
    ) {
      setFieldValue('foreign_qualifications', [])
    }
  }, [foreignQualificationRequired])

  useEffect(() => {
    updateShouldRenderSOS(
      foreignQualificationRequired,
      !!values.foreign_qualifications &&
        !!values.foreign_qualifications[0] &&
        !!values.foreign_qualifications[0].opted_in
    )
  }, [foreignQualificationRequired, values.foreign_qualifications])

  useEffect(() => {
    handlePayrollReportPage(values)
  }, [values?.questions, state, taxTypesByIntent])

  useEffect(() => {
    handleLocalJurisdictionRegistrations(values)
  }, [values?.jurisdictions, localJurisdictionsInState])

  useEffect(() => {
    const locals = localJurisdictions(values.jurisdictions)
    if (locals.length === 0) return

    setLocalJurisdictionSlugs(locals)
  }, [values?.jurisdictions])

  useEffect(() => {
    handleSosPaymentInformation(values)
  }, [values?.sos_registration_selection])

  useEffect(() => {
    const selectionsChanged = !isEqual(
      getTaxTypesByIntent(values),
      taxTypesByIntent
    )

    if (selectionsChanged) {
      setTaxTypesByIntent(getTaxTypesByIntent(values))
    }
  }, [values.tax_registrations[0]])

  useEffect(() => {
    setForeignQualificationRequired(
      requiresForeignQualification(
        state,
        values.questions,
        managedTaxTypesFromTaxRegistrationsInState(values.tax_registrations[0]),
        company?.sos_states
      )
    )
  }, [
    state,
    values.tax_registrations[0],
    values.questions,
    company?.sos_states
  ])

  useMemo(() => {
    if (!api.sandboxMode()) {
      amplitude.getInstance().logEvent('page-visited', {
        page: pages[pageNumber].pageName,
        application_id: application?.id,
        partner_slug: application?.partner_account?.slug,
        registration_request: !!application?.application_invitation?.state
      })
    }
  }, [pageNumber])

  const progress = ((pageNumber / pages.length) * 100.0).toPrecision(2)

  const {
    branding_options: {
      hide_powered_by_middesk = false,
      whitelabel = false
    } = {}
  } = application || {}

  const showPoweredByMiddesk = !!logo && !hide_powered_by_middesk && !whitelabel

  return (
    <>
      <CurrentPage
        {...{
          application,
          pricingData,
          showSOSPages,
          hasDynamicStatePage,
          pageMap,
          showSOSPaymentInformation,
          validateFormAndNavigate,
          onCancel,
          updateValidationSchema,
          error,
          isSubmitting,
          logo,
          progress,
          pageNumber,
          firstLocalPageAt,
          localJurisdictionRegistrations,
          validationSchema,
          gustoIntake,
          foreignQualificationRequired,
          foreignQualificationOnlyRegistration,
          filteredStateQuestions,
          managedRegistrationTypesWithFQ,
          showPoweredByMiddesk
        }}
      />
      <ReactTooltip
        textColor={COLORS.white}
        backgroundColor={COLORS.graphite}
        border
        borderColor={COLORS.dawn}
      />
    </>
  )
}
