import React from 'react'
import { Banner } from '@middesk/components'
import TaxAccountContainer from './TaxAccountContainer'
import { TaxAccountWrapper } from './TaxAgencyRegistrationDetailCard'
import { TaxAccount } from '../../types'

const TaxAccountDetails = ({
  taxAccount,
  status,
  skipCredentials
}: {
  taxAccount: TaxAccount
  status: string
  skipCredentials: boolean
}) => {
  const hasCredentials = !!(taxAccount.password || taxAccount.pin)

  if (skipCredentials && !hasCredentials) {
    return (
      <Banner intent='info' showIcon={false}>
        Middesk does not create credentials for this agency because it is not
        required when registering.
      </Banner>
    )
  }

  // else, if we have manually created credentials, even if the agency doesn't require
  // credential creation, render the credentials section

  return (
    <TaxAccountWrapper>
      <TaxAccountContainer account={taxAccount} status={status} />
    </TaxAccountWrapper>
  )
}

export default TaxAccountDetails
