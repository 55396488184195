import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { parse } from 'qs'

import { theme, Loader, ListPagination } from '@middesk/components'

import { AgencyRegistration, TaxAgencyRegistration } from '../types'

import { getTaxAgencyRegistrationStatusLabel } from '../lib/helpers'
import {
  SECRETARY_OF_STATE_TYPE,
  DEFAULT_PAGE_NUM,
  STATES,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  TAX_AGENCY_REGISTRATION_STATUSES,
  AGENT_TAX_AGENCY_REGISTRATION,
  AGENT_AGENCY_REGISTRATION
} from '../lib/constants'
import {
  APITaxAgencyRegistration,
  GetTaxAgencyRegistrationsResponse
} from '../lib/api/taxAgencyRegistration'
import queryStringSerializer from '../lib/queryStringSerializer'

import ControlRow from './ControlRow'
import FilterCollection from './System/FilterCollection'
import Filter from './System/Filter'
import Card from './System/Card'
import TaxAgencyRegistrationDetailCard from '../pages/Accounts/TaxAgencyRegistrationDetailCard'
import AgencyRegistrationDetailCard from '../pages/Accounts/AgencyRegistrationDetailCard'

const { spacing, colors } = theme

const AccountWrapper = styled.div`
  ${Card} {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${spacing.large};

    > span {
      text-align: center;
    }
  }

  > div:not(:last-child) {
    margin-bottom: ${spacing.normal};
  }
`

const LoadingCard = styled(Card)`
  width: 100%;
`

const NoResultsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  color: ${colors.red};
`

const STATE_TAX_TYPE_OPTIONS = [
  {
    label: 'State Tax Withholdings',
    value: STATE_TAX_WITHHOLDINGS_TYPE
  },
  {
    label: 'State Unemployment Insurance',
    value: STATE_UNEMPLOYMENT_INSURANCE_TYPE
  },
  {
    label: 'Secretary of State',
    value: SECRETARY_OF_STATE_TYPE
  }
]

const LOCAL_TAX_TYPE_OPTIONS = [
  {
    label: 'Local Taxes',
    value: 'local_taxes'
  }
]

const AccountDetails = ({
  companyId,
  showLocals,
  guestDashboard,
  perPage,
  onFilterChange
}: {
  companyId: string
  showLocals: boolean
  guestDashboard: boolean
  perPage: number
  onFilterChange: (newParams: string) => void
}) => {
  const [registrations, setRegistrations] = useState<
    Array<TaxAgencyRegistration | AgencyRegistration>
  >([])

  const [total, setTotal] = useState(0)
  const [fetching, setFetching] = useState(true)
  const [hasMore, setHasMore] = useState(true)

  const location = useLocation()
  const { search } = location
  const params = parse(search, { ignoreQueryPrefix: true })

  const stateFilters = STATES.map(state => ({
    label: state.name,
    value: state.abbr
  }))

  const statusOptions = TAX_AGENCY_REGISTRATION_STATUSES.map(status => {
    return {
      label: getTaxAgencyRegistrationStatusLabel(status),
      value: status
    }
  })

  useEffect(() => {
    setFetching(true)
    setRegistrations([])

    const defaultParams = {
      page: DEFAULT_PAGE_NUM,
      per_page: perPage
    }

    const { ...queryStringParams } = params

    APITaxAgencyRegistration.index(companyId, {
      ...defaultParams,
      ...queryStringParams,
      include_locals: true
    })
      .then((json: GetTaxAgencyRegistrationsResponse) => {
        setRegistrations(json.data)
        setTotal(json.total_count)
        setHasMore(json.has_more)
      })
      .catch((e: any) => {
        if (e.status !== 404) {
          throw e
        }
      })
      .finally(() => setFetching(false))
  }, [search])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [fetching])

  const {
    state = null,
    tax_types = null,
    status = null,
    per_page = perPage,
    page = DEFAULT_PAGE_NUM
  }: {
    [key: string]: any
  } = params as any

  const onPage = (page: number) => {
    if (fetching) {
      return
    }

    onChange({ page })
  }

  const onChange = (nextParams: { [key: string]: any }): void => {
    if (fetching) {
      return
    }

    onFilterChange(queryStringSerializer(params, nextParams))
  }

  const updateTar = (updateIndex: number, newTar: TaxAgencyRegistration) => {
    setRegistrations([
      ...registrations.slice(0, updateIndex),
      newTar,
      ...registrations.slice(updateIndex + 1)
    ])
  }

  const taxTypeOptions = [
    ...STATE_TAX_TYPE_OPTIONS,
    ...(showLocals ? LOCAL_TAX_TYPE_OPTIONS : [])
  ]

  return (
    <>
      <ReactTooltip
        textColor={colors.white}
        backgroundColor={colors.graphite}
        borderColor={colors.dawn}
        border
      />
      <ControlRow>
        <FilterCollection>
          <Filter
            placeholder={'State'}
            options={stateFilters}
            currentValue={state}
            onFilter={(value: string) => {
              onChange({ state: value })
            }}
          />
          {!guestDashboard && (
            <>
              <Filter
                placeholder={'Tax Types'}
                options={taxTypeOptions}
                currentValue={tax_types ? tax_types[0] : undefined}
                onFilter={(value: string) => {
                  onChange({ tax_types: [value] })
                }}
              />
              <Filter
                placeholder={'Status'}
                options={statusOptions}
                currentValue={status}
                onFilter={(value: string) => {
                  onChange({ status: value })
                }}
              />
            </>
          )}
        </FilterCollection>

        {(page != 1 || hasMore) && (
          <ListPagination
            onPage={onPage}
            page={page}
            perPage={per_page}
            total={total}
          />
        )}
      </ControlRow>

      {fetching ? (
        <LoadingCard variant='secondary'>
          <span>
            <Loader size='medium' />
          </span>
        </LoadingCard>
      ) : (
        <AccountWrapper>
          {registrations.map((registration, index) => {
            if (registration.object === AGENT_TAX_AGENCY_REGISTRATION) {
              return (
                <TaxAgencyRegistrationDetailCard
                  key={`tar-card-${registration.id}`}
                  tax_agency_registration={
                    registration as TaxAgencyRegistration
                  }
                  hideTooltip={guestDashboard}
                  updateTar={
                    guestDashboard
                      ? undefined
                      : newTar => updateTar(index, newTar)
                  }
                />
              )
            } else if (registration.object === AGENT_AGENCY_REGISTRATION) {
              return (
                <AgencyRegistrationDetailCard
                  key={`ar-card-${registration.id}`}
                  agency_registration={registration as AgencyRegistration}
                />
              )
            }
          })}
          {guestDashboard && registrations.length === 0 && state && (
            <NoResultsMessage>
              No accounts found in state. Try clearing filters to view results.
            </NoResultsMessage>
          )}
        </AccountWrapper>
      )}
    </>
  )
}

export default AccountDetails
