import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { ContainerProps } from '../../types'
import { DEFAULT_PER_PAGE_COUNT } from '../../lib/constants'
import { APICompanyAccountsOverview } from '../../lib/api/companyAccountsOverview'

import Accounts from './Accounts'
import AccountDetails from '../../components/AccountDetails'

const Details = ({ className }: ContainerProps) => {
  const [hasLocals, setHasLocals] = useState(false)
  const { companyId } = useParams<{ companyId: string }>()
  const { push } = useHistory()

  useEffect(() => {
    APICompanyAccountsOverview.index(companyId).then(({ data }) => {
      setHasLocals(data.some(stateData => stateData.has_locals_in_state))
    })
  }, [])

  const onChange = (newParams: string) => {
    push(`/companies/${companyId}/accounts/details?${newParams}`)
  }

  return (
    <Accounts {...{ className }} companyId={companyId}>
      <AccountDetails
        companyId={companyId}
        guestDashboard={false}
        showLocals={hasLocals}
        perPage={DEFAULT_PER_PAGE_COUNT}
        onFilterChange={onChange}
      />
    </Accounts>
  )
}

export default Details
