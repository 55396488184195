import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { groupBy, isEmpty } from 'lodash'
import * as yup from 'yup'

import { theme } from '@middesk/components'

import { StateDataContext } from '../../contexts/StateDataProvider'
import RegistrationOptions from './RegistrationOptions'
import LocalRegistrationOptions from './LocalRegistrationOptions'
import { ApplicationContext } from '../../contexts/ApplicationProvider'
import {
  BOTH_SUI_AND_SWH_TYPE,
  FOREIGN_QUALIFICATION_TYPE,
  MANAGED_BY_MIDDESK,
  UNMANAGED_BY_MIDDESK
} from '../../lib/constants'
import {
  RegistrationPrice,
  RegistrationType,
  TransactionalPricingData,
  Item,
  Agency,
  SavedForeignQualification,
  FormValues
} from '../../types'
import { AuthContext } from '../../contexts/AuthProvider'
import EinField from '../../pages/CompanyDetails/EinField'
import { localJurisdictions } from '../../lib/helpers'
import ForeignQualificationOptions from './ForeignQualificationOptions'
import { useFormikContext } from 'formik'

const { typography, colors } = theme

const EmptyWrapper = styled.div`
  min-height: 40px;
  color: ${colors.karlLight1};
`

export const formatItemValidations = (format?: string | string[]) => {
  if (!format) return []
  if (Array.isArray(format)) {
    return format
  }

  return [format]
}

export const validItemFormat = (
  value: string | null | undefined,
  formats: string[]
) => {
  if (!value || formats.length === 0) return true // require check happens before this, so we can allow nil here

  return formats.some(format => {
    const regex = new RegExp(`^${format.replaceAll('X', '[a-zA-Z0-9]')}$`)
    return regex.test(value)
  })
}

const agenciesValidations = (taxType: string, agencies: Agency[]) => {
  const taxTypeKey = `${taxType}_selection`
  const allItems = agencies.reduce(
    (items: Item[], agency: Agency) => [...items, ...agency.items],
    []
  )

  return {
    ...allItems.reduce((obj, { key, validation, is_tax_id }: Item) => {
      const formats = formatItemValidations(validation?.format)
      return {
        ...obj,
        [taxTypeKey]: yup.string().nullable(),
        [key]: yup.string().when(taxTypeKey, (value: string) => {
          if (value === UNMANAGED_BY_MIDDESK) {
            return yup
              .string()
              .test('required', 'Required', value =>
                is_tax_id ? (value || '').length > 0 : true
              )
              .test(
                key,
                `Value must be in correct format: ${formats.join(', ')}`,
                value => validItemFormat(value, formats)
              )
              .nullable()
          }
        })
      }
    }, {})
  }
}

const formatValidationSchema = (
  {
    tax_registration: { agencies, requires_sui_and_swh_bundled: bundleRequired }
  }: {
    tax_registration: {
      agencies: Agency[]
      requires_sui_and_swh_bundled: boolean
    }
  },
  taxTypes: RegistrationType[],
  allTaxTypesUnmanaged: boolean,
  foreignQualification?: SavedForeignQualification
) => {
  const agenciesByTaxType = groupBy(agencies, 'tax_type')

  let fq_schema = yup.array().optional().nullable()
  if (foreignQualification?.transfer) {
    fq_schema = fq_schema
      .of(
        yup
          .object({
            sos_registration_id: yup.string().required().nullable()
          })
          .optional()
          .nullable()
      )
      .required()
      .min(1)
  }

  return yup.object().shape({
    ein: yup
      .string()
      .test('isValid', 'EIN is invalid', function (value) {
        const { path, createError } = this

        if (allTaxTypesUnmanaged && !foreignQualification?.state && !value) {
          return createError({
            path,
            message: 'EIN is required'
          })
        }

        if (value && value.replace(/\D+/g, '').length !== 9) {
          return createError({
            path,
            message: 'EIN must be 9 digits'
          })
        }

        return true
      })
      .nullable(),
    tax_registrations: yup
      .array()
      .of(
        yup.object({
          state: yup.string().required('Required').typeError('Required'),
          ...(bundleRequired
            ? agenciesValidations(BOTH_SUI_AND_SWH_TYPE, agencies)
            : taxTypes.reduce((obj, taxType) => {
                return {
                  ...obj,
                  ...agenciesValidations(
                    taxType,
                    agenciesByTaxType[taxType] || []
                  )
                }
              }, {}))
        })
      )
      .nullable(),
    jurisdictions: yup.array().of(yup.string()).min(1),
    foreign_qualifications: fq_schema
  })
}

const EinFieldComponent = (): JSX.Element => (
  <div style={{ marginBottom: typography.sizes.large }}>
    <EinField showPresentAttribute={false} allowEditing={false} />
  </div>
)

const LocalOptions = ({
  localPriceInCents,
  selectedLocalJurisdictions
}: {
  localPriceInCents: number | undefined
  selectedLocalJurisdictions: string[]
}): JSX.Element => (
  <>
    {selectedLocalJurisdictions.map((localJurisdiction, i) => {
      return (
        <LocalRegistrationOptions
          key={`local-${localJurisdiction}-${i}`}
          jurisdiction={localJurisdiction}
          localPriceInCents={localPriceInCents || 0}
        />
      )
    })}
  </>
)

const ForeignQualificationComponent = ({
  selectedJurisdictions,
  pricingData
}: {
  selectedJurisdictions: string[]
  pricingData?: TransactionalPricingData
}) =>
  selectedJurisdictions.includes(FOREIGN_QUALIFICATION_TYPE) ? (
    <ForeignQualificationOptions {...{ selectedJurisdictions, pricingData }} />
  ) : (
    <></>
  )

const UnmanagedItemsIntake = ({
  pricingData,
  updateValidationSchema,
  setDynamicFormFields,
  taxTypes,
  selectedJurisdictions,
  isSubmitPage
}: {
  pricingData?: TransactionalPricingData
  updateValidationSchema: (schema: yup.ObjectSchema) => void
  setDynamicFormFields: (fields: string[]) => void
  taxTypes: RegistrationType[]
  selectedJurisdictions: string[]
  isSubmitPage: boolean
}): JSX.Element => {
  const {
    account: {
      settings: {
        agent: { skip_payment = false }
      }
    }
  } = useContext(AuthContext)
  const { state, taxTypesByIntent } = useContext(ApplicationContext)
  const { stateAgencyData } = useContext(StateDataContext)
  const {
    values: { foreign_qualifications }
  } = useFormikContext<FormValues>()
  const agencyData = stateAgencyData.find(({ abbr }) => abbr === state)
  const statePrices =
    !skip_payment && pricingData
      ? pricingData.tax_registration_current_prices[state]
      : []
  const localPriceInCents =
    pricingData && pricingData.local_taxes_price_in_cents

  const selectedLocalJurisdictions = localJurisdictions(selectedJurisdictions)

  const allTaxTypesUnmanaged =
    isEmpty(taxTypesByIntent[MANAGED_BY_MIDDESK]) &&
    isEmpty(selectedLocalJurisdictions)

  useEffect(() => {
    if (agencyData) {
      const schema = formatValidationSchema(
        agencyData,
        taxTypes,
        allTaxTypesUnmanaged,
        foreign_qualifications ? foreign_qualifications[0] : undefined
      )

      updateValidationSchema(schema)
      // Generate Formik keys for dynamically added fields
      const keys = agencyData.tax_registration.agencies
        .map((agency: Agency) => agency.items.map(item => item.key))
        .flat()
      // Add FEIN key: 'ein'
      setDynamicFormFields(
        keys.map((key: string) => `tax_registrations[0].${key}`).concat('ein')
      )
    }
  }, [agencyData, allTaxTypesUnmanaged, foreign_qualifications])

  if (!agencyData) return <></>

  const {
    tax_registration: { requires_sui_and_swh_bundled: bundleRequired, agencies }
  } = agencyData

  const filteredAgencies = agencies.filter(({ tax_type }: Agency) => {
    return bundleRequired || taxTypes.includes(tax_type as RegistrationType)
  })

  if ((selectedJurisdictions || []).length === 0) {
    return (
      <EmptyWrapper>
        The details of your selected government accounts will appear here.
      </EmptyWrapper>
    )
  }

  if (bundleRequired) {
    return (
      <>
        {selectedJurisdictions.includes(BOTH_SUI_AND_SWH_TYPE) && (
          <RegistrationOptions
            taxType={BOTH_SUI_AND_SWH_TYPE}
            agencies={filteredAgencies}
            priceInCents={statePrices.reduce(
              (sum: number, { amount_cents }: RegistrationPrice) =>
                (sum += amount_cents),
              0
            )}
          />
        )}
        <LocalOptions
          localPriceInCents={localPriceInCents}
          selectedLocalJurisdictions={selectedLocalJurisdictions}
        />
        <ForeignQualificationComponent
          {...{ selectedJurisdictions, pricingData }}
        />
        {isSubmitPage && <EinFieldComponent />}
      </>
    )
  }

  const agenciesByTaxType = groupBy(filteredAgencies, 'tax_type')
  return (
    <>
      {Object.keys(agenciesByTaxType).map((taxType: string) => {
        if (selectedJurisdictions.includes(taxType)) {
          return (
            <RegistrationOptions
              key={taxType}
              taxType={taxType}
              agencies={agenciesByTaxType[taxType]}
              priceInCents={
                statePrices.find(
                  ({ type }: RegistrationPrice) => type === taxType
                )?.amount_cents
              }
            />
          )
        }
      })}
      <LocalOptions
        localPriceInCents={localPriceInCents}
        selectedLocalJurisdictions={selectedLocalJurisdictions}
      />
      <ForeignQualificationComponent
        {...{ selectedJurisdictions, pricingData }}
      />
      {isSubmitPage && <EinFieldComponent />}
    </>
  )
}

export default UnmanagedItemsIntake
