import React, { FC } from 'react'
import 'react-flexbox-grid/dist/react-flexbox-grid.css'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { GoogleOAuthProvider } from '@react-oauth/google'
import NewRegistrationIntake from './components/NewRegistrationIntake'
import GuestNewRegistrationIntake from './components/GuestNewRegistrationIntake'
import GuestTransfersIntake from './components/GuestTransfersIntake'
import SOSRegistrationLanding from './components/SOSRegistrationLanding'
import SOSRegistrationConfirmation from './components/SOSRegistrationConfirmation'
import ConnectToMiddesk from './components/ConnectToMiddesk'
import ErrorPage from './components/ErrorPage'
import AdminRoute from './components/AdminRoute'
import OAuthDemo from './components/OAuthDemo'
import OpenRoute from './components/OpenRoute'
import PrivateRoute from './components/PrivateRoute'
import { AuthProvider } from './contexts/AuthProvider'
import { StateDataProvider } from './contexts/StateDataProvider'
import GlobalStyle from './GlobalStyle'
import Home from './pages/Home'
import './index.css'
import initializeAmplitude from './lib/initializeAmplitude'
import {
  Actions,
  Confirm,
  ForgotPassword,
  Login,
  Documents,
  Communications,
  PackageSelection,
  ResetPassword,
  Terms,
  Terms2021,
  Terms2024,
  Updates,
  SubmitBusinessDetails,
  InfoRequestForm,
  Team,
  GuestInvitations,
  EmailLinkCallback
} from './pages'
import Logout from './pages/Logout'
import PartnerDemo from './pages/PartnerDemo'
import Overview from './pages/Accounts/Overview'
import Details from './pages/Accounts/Details'
import ConfirmInformation from './pages/ConfirmInformation/index'
import SignupNew from './pages/SignupNew'
import ConfirmEmailNew from './pages/ConfirmEmailNew'
import SetUserInfo from './pages/SetUserInfo'
import TransfersIntake from './components/TransfersIntake/TransfersIntake'
import TransferRegistrations from './components/TransferRegistrations'
import GeoLocationProvider from './contexts/GeoLocationProvider'
import ImpersonationBanner from './components/ImpersonationBanner'
import { ApplicationProvider } from './contexts/ApplicationProvider'
import Billing from './pages/Billing'
import SSOCallback from './pages/SSOCallback'
import PartnerReferralPage from './pages/PartnerReferralPage'
import DirectUpgrade from './pages/DirectUpgrade'
import AccountProvider from './contexts/AccountProvider'
import DefaultCompanyRedirect from './components/DefaultCompanyRedirect'
import CompanyProvider from './components/CompanyProvider'
import Invitations from './components/Invitations'
import CompaniesList from './pages/Companies/List'
import GuestDashboard from './components/GuestDashboard'

initializeAmplitude()

const App: FC = () => {
  return (
    <Router>
      <AuthProvider>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
        >
          <AccountProvider>
            <CompanyProvider>
              <ApplicationProvider>
                <ToastProvider placement='bottom-center' autoDismiss>
                  <StateDataProvider>
                    <GeoLocationProvider>
                      <GlobalStyle />
                      <ImpersonationBanner />
                      <Switch>
                        <OpenRoute path='/login' component={Login} />
                        <OpenRoute path='/signup' component={SignupNew} />
                        <OpenRoute
                          path='/referrals/:referral_code'
                          component={PartnerReferralPage}
                        />
                        <Route path='/sso-callback' component={SSOCallback} />
                        <Route
                          path='/forgot-password'
                          component={ForgotPassword}
                        />
                        <Route path='/terms-2021-01-04' component={Terms2021} />
                        <Route path='/terms-2024-02-01' component={Terms2024} />
                        <Route path='/terms' component={Terms} />
                        <Route path='/error' component={ErrorPage} />
                        <Route path='/confirm' component={Confirm} />
                        <Route
                          path='/companies/:companyId/confirm-information'
                          component={ConfirmInformation}
                        />
                        <Route
                          path='/companies/:companyId/edit-information'
                          component={ConfirmInformation}
                        />
                        <PrivateRoute
                          path='/direct-upgrade'
                          component={DirectUpgrade}
                        />
                        <PrivateRoute
                          path='/select-package'
                          component={PackageSelection}
                        />
                        <Route
                          path='/oauth/authorize'
                          component={ConnectToMiddesk}
                        />
                        <Route
                          path='/reset-password'
                          component={ResetPassword}
                        />
                        <Route path='/partner-demo' component={PartnerDemo} />
                        <Route
                          path='/confirm-email'
                          component={ConfirmEmailNew}
                        />
                        <Route
                          path='/sos_registration/:id'
                          component={SOSRegistrationLanding}
                        />
                        <Route
                          path='/sos_registration_confirmation/:id'
                          component={SOSRegistrationConfirmation}
                        />
                        <Route
                          path='/guest_dashboard/:companyId'
                          component={GuestDashboard}
                        />
                        <Route
                          path='/guest/applications/:id'
                          component={GuestNewRegistrationIntake}
                        />
                        <Route
                          path='/guest/transfers/:id'
                          component={GuestTransfersIntake}
                        />
                        <Route
                          path='/info_requests/:id'
                          component={InfoRequestForm}
                        />
                        <Route
                          path='/email_link_callback'
                          component={EmailLinkCallback}
                        />
                        <PrivateRoute
                          path='/set-user-info'
                          component={SetUserInfo}
                        />
                        <PrivateRoute
                          path='/applications/:id'
                          component={NewRegistrationIntake}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/accounts/overview'
                          component={(props: any) => (
                            <Overview
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute path='/billing' component={Billing} />
                        <PrivateRoute
                          path='/companies/:companyId/accounts/details'
                          component={(props: any) => (
                            <Details
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute path='/signout' component={Logout} />
                        <PrivateRoute
                          path='/submit-business'
                          component={SubmitBusinessDetails}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/transfer-registrations'
                          component={(props: any) => (
                            <TransferRegistrations
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/documents'
                          component={(props: any) => (
                            <Documents
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/communications'
                          component={(props: any) => (
                            <Communications
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/actions'
                          component={(props: any) => (
                            <Actions
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute
                          path='/oauth/demo'
                          component={OAuthDemo}
                        />
                        <AdminRoute path='/team' component={Team} />
                        <PrivateRoute
                          path='/companies/:companyId/home'
                          component={(props: any) => (
                            <Home {...props} key={window.location.pathname} />
                          )}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/updates'
                          component={(props: any) => (
                            <Updates
                              {...props}
                              key={window.location.pathname}
                            />
                          )}
                        />
                        <PrivateRoute
                          path='/transfers/:id'
                          component={TransfersIntake}
                        />
                        <Route
                          path='/guest/invitations'
                          component={GuestInvitations}
                        />
                        <PrivateRoute
                          path='/companies/:companyId/invitations'
                          component={Invitations}
                        />
                        <Redirect from='/invitations' to='/guest/invitations' />
                        <PrivateRoute
                          path='/companies'
                          component={CompaniesList}
                        />
                        <DefaultCompanyRedirect
                          path='/'
                          redirectPath='/companies/:companyId/home'
                        />
                      </Switch>
                    </GeoLocationProvider>
                  </StateDataProvider>
                </ToastProvider>
              </ApplicationProvider>
            </CompanyProvider>
          </AccountProvider>
        </GoogleOAuthProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
