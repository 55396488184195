import { TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import Address from '../components/Address'
import { FormValues } from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { GapWrapper } from '../components/Page'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { ErrorContainer, FieldError } from './EditingIndividual'
import { disableScrollAction } from '../lib/helpers'

const GenericBusinessErrorMessage = () => (
  <ErrorContainer>Please review validation errors</ErrorContainer>
)

export const agentBusinessSchema = yup.object().shape({
  legal_name: yup
    .string()
    .required('Legal name required')
    .typeError('Legal name required')
    .nullable(),
  dba_name: yup.string().optional().nullable(),
  address: yup.object({
    address_line1: yup
      .string()
      .required('Address required')
      .typeError('Address required'),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().required('City required').typeError('City required'),
    state: yup.string().required('State required').typeError('State required'),
    postal_code: yup
      .string()
      .required('ZIP code required')
      .typeError('ZIP code required')
  }),
  ein: yup
    .string()
    .test(
      'len',
      'FEIN must be 9 characters',
      value => !!value && value.replace(/\D+/g, '').length === 9
    )
    .required('FEIN required')
    .typeError('FEIN required')
    .nullable(),
  contact_email: yup
    .string()
    .email('Email must be valid')
    .required('Contact email required')
    .typeError('Email must be valid')
    .nullable(),
  ownership_start_date: yup
    .mixed()
    .test('isValid', 'Ownership start date is invalid', function (value) {
      const { path, createError, parent } = this

      if (value) {
        if (!moment(value).isValid()) {
          return createError({
            path,
            message: 'Ownership start date is required'
          })
        }

        if (parent.formation_date) {
          if (new Date(value) < new Date(parent.formation_date)) {
            return createError({
              path,
              message: `Ownership start date must be on or after the business formation date: ${moment(
                parent.formation_date
              ).format('MM/DD/YYYY')}`
            })
          }
        }

        if (moment(value).isAfter(moment())) {
          return createError({
            path,
            message: 'Ownership start date must be before today'
          })
        }
      } else {
        return createError({
          path,
          message: 'Ownership start date is required'
        })
      }

      return true
    }),
  ownership_percentage: yup
    .number()
    .min(0, 'Ownership percentage must be greater than or equal to 0')
    .max(100, 'Ownership percentage must be less than or equal to 100')
    .required('Ownership percentage is required')
    .typeError('Ownership percentage must be a number')
    .nullable()
})

const EditingBusiness = ({
  onAddBussiness,
  onCancel,
  showPoweredByMiddesk,
  progress
}: {
  onAddBussiness: () => void
  onCancel: any
  showPoweredByMiddesk?: boolean
  progress?: string
}): JSX.Element => {
  const { values, setFieldValue, errors } = useFormikContext<FormValues>()

  const [showFullValidation, setShowFullValidation] = useState<boolean>(false)

  useEffect(() => {
    // For Validation
    setFieldValue(`editing_owner.formation_date`, values.formation_date)
  }, [values.formation_date])

  const validateThenSave = () => {
    setShowFullValidation(true)

    if (isEmpty(errors)) {
      onAddBussiness()
    }
  }

  return (
    <>
      <GapWrapper>
        <div>
          <TextField
            autoFocus
            name={`editing_owner.legal_name`}
            label='Legal name of business'
            placeholder='Enter business legal name'
          />
          <FieldError
            fieldName='editing_owner.legal_name'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            name={`editing_owner.dba_name`}
            label='Doing business as (DBA) name'
            placeholder='Enter business dba name'
            optional
          />
          <FieldError
            fieldName='editing_owner.dba_name'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            name={`editing_owner.contact_email`}
            label='Contact email'
            placeholder='Enter business contact email'
          />
          <FieldError
            fieldName='editing_owner.contact_email'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            type='ein'
            name={`editing_owner.ein`}
            label='Federal employer identification number (FEIN)'
            placeholder='XX-XXXXXXX'
          />
          <FieldError
            fieldName='editing_owner.ein'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Ownership start date'
            placeholder='MM/DD/YYYY'
            type='date'
            name={`editing_owner.ownership_start_date`}
          />
          <FieldError
            fieldName='editing_owner.ownership_start_date'
            showFullValidation={showFullValidation}
          />
        </div>
        <div>
          <TextField
            label='Ownership percentage'
            placeholder='Enter a percentage'
            type='number'
            min='0'
            max='100'
            name={`editing_owner.ownership_percentage`}
            onFocus={disableScrollAction}
          />
          <FieldError
            fieldName='editing_owner.ownership_percentage'
            showFullValidation={showFullValidation}
          />
        </div>
        <Address
          name={`editing_owner.address`}
          label='Business address'
          showErrorMessage
        />
      </GapWrapper>
      {showFullValidation && !isEmpty(errors) && (
        <GenericBusinessErrorMessage />
      )}
      <NewRegistrationIntakeFooter
        {...{
          values,
          onCancel: onCancel,
          isSubmitting: false,
          isDisabled: showFullValidation && !isEmpty(errors),
          onClick: validateThenSave,
          progress,
          title: 'Ownership information',
          submitText: 'Add business',
          showPoweredByMiddesk
        }}
      />
    </>
  )
}

export default EditingBusiness
